/**
 * View component for /firm/:firmId/workspaces/:clientId/files
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import * as requestActions from '../requestActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import filterUtils from '../../../global/utils/filterUtils'
import PageTabber from '../../../global/components/pagination/PageTabber.js.jsx'
import MobileActionsOption from '../../../global/components/helpers/MobileActionOptions.js.jsx'
import CloseWrapper from '../../../global/components/helpers/CloseWrapper.js.jsx'
import DropdownButton from '../../../global/components/helpers/DropdownButton.js.jsx'
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

// import components
import RequestTableListItem from './RequestTableListItem.js.jsx'
import { NumberInput } from '../../../global/components/forms'

const requestListActionListItems = [
  {
    label: 'Create New Request List',
    name: 'single_request_list',
    value: 'single_request_list',
  },
  {
    label: 'Bulk Request List Upload',
    name: 'bulk_request_list',
    value: 'bulk_request_list',
  },
]

class RequestList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      showMobileActionOption: false,
      searchHeaderColumns: {
        name: { disableSearch: true, searchText: '' },
        totalTasks: { disableSearch: true, searchText: '' },
        totalUploadedFiles: { disableSearch: true, searchText: '' },
        createdBy: { disableSearch: true, searchText: '' },
        lastUpdated: { disableSearch: true, searchText: '' },
      },
    }
    this._bind(
      '_handleFilter',
      '_handleCloseMobileOption',
      '_handleRequestListAction',
      '_toggleHeaderSearch',
      '_changeHeaderSearch'
    )
  }

  componentDidMount() {}

  _handleFilter(sortBy) {
    const { requestList, dispatch, listArgs } = this.props
    let newFilter = requestList.filter
    if (
      requestList.filter.sortBy &&
      requestList.filter.sortBy.indexOf('-') < 0
    ) {
      sortBy = '-' + sortBy
    } else {
      sortBy = sortBy.substring(0)
    }
    newFilter.sortBy = sortBy
    dispatch(requestActions.setFilter(newFilter, listArgs))
  }

  _handleCloseMobileOption(e) {
    e.stopPropagation()
    this.setState({ showMobileActionOption: false })
  }

  _handleRequestListAction(value) {
    if (value === 'single_request_list' || value === 'bulk_request_list') {
      this.props.handleRequestListShowModal(value)
    }
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { requestStore, dispatch } = this.props
    const { filterHeaders } = requestStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(requestActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { requestStore, dispatch } = this.props
    const { filterHeaders } = requestStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(requestActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  render() {
    const {
      handleRequestListShowModal,
      handleToggleSelectAll,
      requestList,
      sortedAndFilteredList,
      orderedList,
      paginatedList,
      handleSelectRequest,
      selectedRequestIds,
      clearSelectedRequestIds,
      handleSetPagination,
      setPerPage,
      userMap,
      handleUpdateRequest,
      isViewing,
      handleQuery,
    } = this.props

    const { showMobileActionOption, searchHeaderColumns } = this.state

    const isFiltered =
      sortedAndFilteredList &&
      orderedList &&
      sortedAndFilteredList.length !== orderedList.length
    const filter =
      requestList && requestList.filter && requestList.filter.sortBy
    const allRequestIdsSelected =
      selectedRequestIds && selectedRequestIds.length
        ? paginatedList.every(p => selectedRequestIds.includes(p._id))
        : false

    return (
      <div className="file-list-wrapper request-list">
        {isViewing === 'portal' ? null : (
          <div className="yt-toolbar">
            <div className="yt-tools space-between">
              <div className="-options -left"></div>
              <div className="-options -right">
                <DropdownButton
                  label="New Request List"
                  selectedCount={null}
                  select={this._handleRequestListAction}
                  displayKey="label"
                  items={requestListActionListItems}
                  selected={null}
                  valueKey="value"
                  disabled={false}
                />
              </div>
            </div>
          </div>
        )}
        <hr className="-mobile-yt-hide" />
        <div className="yt-table table firm-table -workspace-table truncate-cells -yt-edit-table -request-list-table">
          <div className="table-caption">
            <PageTabber
              totalItems={
                isFiltered && requestList.pagination
                  ? orderedList.length
                  : sortedAndFilteredList.length
              }
              totalPages={Math.ceil(
                isFiltered && requestList.pagination
                  ? orderedList.length / requestList.pagination.per
                  : sortedAndFilteredList.length / 50,
              )}
              pagination={requestList.pagination}
              setPagination={handleSetPagination}
              setPerPage={this.props.setPerPage}
              viewingAs="top"
              itemName="request list"
              searchText="Search..."
              enableSearch={true}
              handleQuery={handleQuery}
            />
          </div>
          <div className="-table-horizontal-scrolling">
            <div className="table-head">
              {isViewing === 'portal' ? null : (
                <div className="table-cell"></div>
              )}
              <div className="table-cell -title sortable _40">
                <div
                  className="-table-header-title"
                  onClick={() => this._handleFilter('name')}
                >
                  Name
                  {filter && filter == 'name' ? (
                    <i className="fad fa-sort-down"></i>
                  ) : filter && filter == '-name' ? (
                    <i className="fad fa-sort-up"></i>
                  ) : (
                    <i className="fad fa-sort"></i>
                  )}
                </div>
                <div className="-table-header-search">
                  <CheckboxInput
                    name="name"
                    value={!searchHeaderColumns.name.disableSearch}
                    checked={!searchHeaderColumns.name.disableSearch}
                    change={this._toggleHeaderSearch}
                  />
                  <TextInput
                    blur={() => console.log('blur')}
                    change={this._changeHeaderSearch}
                    name="name"
                    value={searchHeaderColumns.name.searchText}
                    disabled={searchHeaderColumns.name.disableSearch}
                    placeholder="Search name"
                  />
                </div>
              </div>
              <div className="table-cell _15">
                <div className="-table-header-title">Tasks</div>
                <div className="-table-header-search">
                  <CheckboxInput
                    name="totalTasks"
                    value={!searchHeaderColumns.totalTasks.disableSearch}
                    checked={!searchHeaderColumns.totalTasks.disableSearch}
                    change={this._toggleHeaderSearch}
                  />
                  <NumberInput
                    change={this._changeHeaderSearch}
                    firefoxDisplayFix={true}
                    min="0"
                    name="totalTasks"
                    required={false}
                    step="1"
                    value={searchHeaderColumns.totalTasks.searchText}
                    disabled={searchHeaderColumns.totalTasks.disableSearch}
                    placeholder="Search tasks"
                  />
                </div>
              </div>
              <div className="table-cell _15">
                <div className="-table-header-title">Uploaded files</div>
                <div className="-table-header-search">
                  <CheckboxInput
                    name="totalUploadedFiles"
                    value={
                      !searchHeaderColumns.totalUploadedFiles.disableSearch
                    }
                    checked={
                      !searchHeaderColumns.totalUploadedFiles.disableSearch
                    }
                    change={this._toggleHeaderSearch}
                  />
                  <NumberInput
                    change={this._changeHeaderSearch}
                    firefoxDisplayFix={true}
                    min="0"
                    name="totalUploadedFiles"
                    required={false}
                    step="1"
                    value={searchHeaderColumns.totalUploadedFiles.searchText}
                    disabled={
                      searchHeaderColumns.totalUploadedFiles.disableSearch
                    }
                    placeholder="Search uploaded"
                  />
                </div>
              </div>
              <div className="table-cell _30">
                <div className="-table-header-title">Created By</div>
                <div className="-table-header-search">
                  <CheckboxInput
                    name="createdBy"
                    value={!searchHeaderColumns.createdBy.disableSearch}
                    checked={!searchHeaderColumns.createdBy.disableSearch}
                    change={this._toggleHeaderSearch}
                  />
                  <TextInput
                    blur={() => console.log('blur')}
                    change={this._changeHeaderSearch}
                    name="createdBy"
                    value={searchHeaderColumns.createdBy.searchText}
                    disabled={searchHeaderColumns.createdBy.disableSearch}
                    placeholder="Search created by"
                  />
                </div>
              </div>
              <div className="table-cell -date sortable">
                <div
                  className="-table-header-title"
                  onClick={() => this._handleFilter('date')}
                >
                  Last Updated
                  {filter && filter == 'date' ? (
                    <i className="fad fa-sort-up"></i>
                  ) : filter && filter == '-date' ? (
                    <i className="fad fa-sort-down"></i>
                  ) : (
                    <i className="fad fa-sort"></i>
                  )}
                </div>
                <div className="-table-header-search">
                  <CheckboxInput
                    name="lastUpdated"
                    value={!searchHeaderColumns.lastUpdated.disableSearch}
                    checked={!searchHeaderColumns.lastUpdated.disableSearch}
                    change={this._toggleHeaderSearch}
                  />
                  <TextInput
                    blur={() => console.log('blur')}
                    change={this._changeHeaderSearch}
                    name="lastUpdated"
                    value={searchHeaderColumns.lastUpdated.searchText}
                    disabled={searchHeaderColumns.lastUpdated.disableSearch}
                    placeholder="Search last updated"
                  />
                </div>
              </div>
            </div>
            {paginatedList && paginatedList.length > 0 ? (
              paginatedList.map((request, i) => (
                <RequestTableListItem
                  key={i}
                  request={request}
                  checked={selectedRequestIds.includes(request._id)}
                  handleSelectRequest={handleSelectRequest}
                  userMap={userMap}
                  handleUpdateRequest={handleUpdateRequest}
                  isViewing={isViewing}
                />
              ))
            ) : (
              <div className="table-head empty-state">
                <div
                  className="table-cell"
                  colSpan="6"
                >
                  <em>No Request List</em>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

RequestList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

RequestList.defaultProps = {}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  const { requestList, requestMap } = props
  const listItems = props.sortedAndFilteredList
  const requestStore = store.request

  /**
   * REGARDING PAGINATION: Pagination would normally be handled on the parent component WorkspaceFiles.
   * The listArgs in WorkspaceFiles.state are not accessible from that component's mapStoreToProps
   * function. We have to paginate the list here instead since it is passed to this component as a prop
   * with no need to be aware of the listArgs.
   */

  const sortedAndFilteredList = []
  let paginatedList = []
  let orderedList = []
  const filter = requestList.filter
  const query = requestList.query || ''
  const sortBy = filter ? filter.sortBy : 'date'

  if (listItems && listItems.length) {
    // FILTER BY QUERY
    let queryTestString = ('' + query).toLowerCase().trim()
    queryTestString = queryTestString.replace(/[^a-zA-Z0-9]/g, '') // replace all non-characters and numbers

    listItems.forEach(item => {
      let createdByFullName = ''

      if (store.user.byId[item._createdBy]) {
        const user = store.user.byId[item._createdBy]
        createdByFullName = user.firstname || ''
        createdByFullName +=
          (createdByFullName ? ' ' : '') + user.lastname || ''
      }

      let showMe = true
      if (queryTestString && queryTestString.trim()) {
        showMe = item && filterUtils.filterTag(queryTestString, item)
      }

      if (
        showMe &&
        !!requestStore.filterHeaders &&
        !!requestStore.filterHeaders.name &&
        requestStore.filterHeaders.name.trim()
      ) {
        const name = requestStore.filterHeaders.name.toLowerCase()
        showMe = item.name && item.name.toLowerCase().indexOf(name) > -1
      }

      if (
        showMe &&
        !!requestStore.filterHeaders &&
        (!!requestStore.filterHeaders.totalTasks ||
          requestStore.filterHeaders.totalTasks === 0)
      ) {
        let totalTasks = requestStore.filterHeaders.totalTasks
        totalTasks = Number(totalTasks)
        item.totalTasks = (item.totalTasks && Number(item.totalTasks)) || 0
        if (totalTasks !== item.totalTasks) showMe = false
      }

      if (
        showMe &&
        !!requestStore.filterHeaders &&
        (!!requestStore.filterHeaders.totalUploadedFiles ||
          requestStore.filterHeaders.totalUploadedFiles === 0)
      ) {
        let totalUploadedFiles = requestStore.filterHeaders.totalUploadedFiles
        totalUploadedFiles = Number(totalUploadedFiles)
        item.totalUploadedFiles =
          (item.totalUploadedFiles && Number(item.totalUploadedFiles)) || 0
        if (totalUploadedFiles !== item.totalUploadedFiles) showMe = false
      }

      if (
        showMe &&
        !!requestStore.filterHeaders &&
        !!requestStore.filterHeaders.createdBy &&
        requestStore.filterHeaders.createdBy.trim()
      ) {
        const createdBy = requestStore.filterHeaders.createdBy.toLowerCase()
        showMe =
          createdByFullName &&
          createdByFullName.toLowerCase().indexOf(createdBy) > -1
      }

      if (
        showMe &&
        !!requestStore.filterHeaders &&
        !!requestStore.filterHeaders.lastUpdated &&
        requestStore.filterHeaders.lastUpdated.trim()
      ) {
        const lastUpdated = requestStore.filterHeaders.lastUpdated.toLowerCase()
        const updatedAt =
          item.updated_at &&
          new Date(item.updated_at).toLocaleDateString('en-US')
        showMe = updatedAt && updatedAt.toLowerCase().indexOf(lastUpdated) > -1
      }

      if (showMe) sortedAndFilteredList.push(item)
    })

    // TODO: in future, separate filtering and sorting
    // SORT THE LIST
    switch (sortBy) {
      case 'name':
        orderedList = _.orderBy(
          sortedAndFilteredList,
          [item => item.name.toLowerCase()],
          ['asc']
        )
        break
      case '-name':
        orderedList = _.orderBy(
          sortedAndFilteredList,
          [item => item.name.toLowerCase()],
          ['desc']
        )
        break
      case 'date':
        orderedList = _.orderBy(
          sortedAndFilteredList,
          [item => item.updated_at],
          ['asc']
        )
        break
      case '-date':
        orderedList = _.orderBy(
          sortedAndFilteredList,
          [item => item.updated_at],
          ['desc']
        )
        break
      default:
        orderedList = _.orderBy(
          sortedAndFilteredList,
          [item => item.name.toLowerCase()],
          ['asc']
        )
    }
  }

  // APPLY PAGINATION
  const pagination = requestList.pagination || { page: 1, per: 50 }
  const start = (pagination.page - 1) * pagination.per
  const end = start + pagination.per
  paginatedList = _.slice(orderedList, start, end)

  return {
    loggedInUser: store.user.loggedIn.user,
    sortedAndFilteredList,
    orderedList,
    paginatedList,
    requestStore,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestList))
