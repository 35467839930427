export const domains = {
    "cluster1": [
        "rothman.imaginetime.com",
        "jobox.imaginetime.com",
        "olatxs.imaginetime.com",
        "divorceaccounting.imaginetime.com",
        "donzecpa.imaginetime.com",
        "comstockconsulting.imaginetime.com",
        "paulgarciacpa.imaginetime.com",
        "philipwheatley.imaginetime.com",
        "wilsonjohns.imaginetime.com",
        "shannonhorse.imaginetime.com",
        "emaccounting.imaginetime.com",
        "rdvcpa.imaginetime.com",
        "cbew.imaginetime.com",
        "johnbainescpa.imaginetime.com",
        "resolutionpayroll.imaginetime.com",
        "roushcpa.imaginetime.com",
        "testfirmforpaula.imaginetime.com",
        "hawkassociates.imaginetime.com",
        "lohrey.imaginetime.com",
        "tagcpas.imaginetime.com",
        "btgfllc.imaginetime.com",
        "theaccountingfirm.imaginetime.com",
        "jrgtaxservice.imaginetime.com",
        "volzcpa.imaginetime.com",
        "mccranie.imaginetime.com",
        "ottinger.imaginetime.com",
        "sillsandassociates.imaginetime.com",
        "dipardoeremic.imaginetime.com",
        "fisheraccounting.imaginetime.com",
        "carbocpa.imaginetime.com",
        "kampboercpa.imaginetime.com",
        "hapb.imaginetime.com",
        "lasala.imaginetime.com",
        "bmkr.imaginetime.com",
        "warrenstonecpa.imaginetime.com",
        "lbrook.imaginetime.com",
        "aardahlaccounting.imaginetime.com",
        "howe-associates.imaginetime.com",
        "universalaccounting.imaginetime.com",
        "quinones.imaginetime.com",
        "kerrbusiness.imaginetime.com",
        "dollinscpafiling.imaginetime.com",
        "g9financial.imaginetime.com",
        "rvacpa.imaginetime.com",
        "thevineadvisors.imaginetime.com",
        "sbas.imaginetime.com",
        "arnoldadvisorsllc.imaginetime.com",
        "wfallscpa.imaginetime.com",
        "demarestcpa.imaginetime.com",
        "kwmcpa.imaginetime.com",
        "evergreencpas.imaginetime.com",
        "morrillaccounting.imaginetime.com",
        "fitchgraves.imaginetime.com",
        "wandacpa.imaginetime.com",
        "kriseacott.imaginetime.com",
        "gbcpas.imaginetime.com",
        "sanducpa.imaginetime.com",
        "johngreenleepa.imaginetime.com",
        "funk-cpa.imaginetime.com",
        "gac-cpa.imaginetime.com",
        "luongocpa.imaginetime.com",
        "oehlke-cpa.imaginetime.com",
        "kaushal.imaginetime.com",
        "chstax.imaginetime.com",
        "ellisgroup.imaginetime.com",
        "levincpas.imaginetime.com",
        "donnadilley.imaginetime.com",
        "amtsolutions.imaginetime.com",
        "facilitiesadvisors.imaginetime.com",
        "ipcaz.imaginetime.com",
        "lentz.imaginetime.com",
        "andanaconsulting.imaginetime.com",
        "archilogix.imaginetime.com",
        "mosesandschreiber.imaginetime.com",
        "rodinecpa.imaginetime.com",
        "baxterlaw.imaginetime.com",
        "legacytax.imaginetime.com",
        "buxkempertax.imaginetime.com",
        "dvcpas.imaginetime.com",
        "rizolve.imaginetime.com",
        "kjonescpa.imaginetime.com",
        "leetaxllc.imaginetime.com",
        "devenscpa.imaginetime.com",
        "happybalancebk.imaginetime.com",
        "landon.imaginetime.com",
        "cosmolex.imaginetime.com",
        "pkvcpallc.imaginetime.com",
        "shncpa.imaginetime.com",
        "ckbizs.imaginetime.com",
        "berkowitzcpas.imaginetime.com",
        "reynoldscompany.imaginetime.com",
        "buckleyandendow.imaginetime.com",
        "mfghi.imaginetime.com",
        "theresamillsjohnson.imaginetime.com",
        "redwinecpa.imaginetime.com",
        "regalcourtreporting.imaginetime.com",
        "wbkania.imaginetime.com"
    ],
    "cluster2": [
        "kdrakelaw.imaginetime.com",
        "hisle.imaginetime.com",
        "hrth.imaginetime.com",
        "hydetax.imaginetime.com",
        "houstonfinancialforensics.imaginetime.com",
        "caroncaron.imaginetime.com",
        "olcllp.imaginetime.com",
        "rswycpas.imaginetime.com",
        "rpegg.imaginetime.com",
        "scottcallicottecpa.imaginetime.com",
        "rothspellman.imaginetime.com",
        "amick.imaginetime.com",
        "kristitaylorcpa.imaginetime.com",
        "jma-cpas.imaginetime.com",
        "jimdixoncpa.imaginetime.com",
        "angelacpa.imaginetime.com",
        "davedillard.imaginetime.com",
        "bengio.imaginetime.com",
        "dohancpa.imaginetime.com",
        "stacyhammondcpapllc.imaginetime.com",
        "hcwcpa.imaginetime.com",
        "mhccpas.imaginetime.com",
        "myersbrettholtz.imaginetime.com",
        "tomrobinsoncpa.imaginetime.com",
        "sunnelaw.imaginetime.com",
        "smythcpa.imaginetime.com",
        "cusmanoandcompany.imaginetime.com",
        "wilkcpa.imaginetime.com",
        "beverlydahlem.imaginetime.com",
        "curtiscpallc.imaginetime.com",
        "wyoaccounting.imaginetime.com",
        "taxcentersofgeorgia.imaginetime.com",
        "lambrightandgutta.imaginetime.com",
        "basiledavis.imaginetime.com",
        "accountingplus.imaginetime.com",
        "vlccpa.imaginetime.com",
        "filenercpa.imaginetime.com",
        "jancibaxtercpa.imaginetime.com",
        "eaccountingservices.imaginetime.com",
        "rushcpa.imaginetime.com",
        "mrinkcpa.imaginetime.com",
        "rocketmatter.imaginetime.com",
        "wmcondon.imaginetime.com",
        "saccalhester.imaginetime.com",
        "vromancpa.imaginetime.com",
        "teague-cpa.imaginetime.com",
        "reinsmacpa.imaginetime.com",
        "easybooks.imaginetime.com",
        "dualvision.imaginetime.com",
        "gordy-cpa.imaginetime.com",
        "murphybunch.imaginetime.com",
        "vkdcpa.imaginetime.com",
        "cpaadvisorsgroup.imaginetime.com",
        "pacificrimtaxes.imaginetime.com",
        "floydmalito.imaginetime.com",
        "wilmoth.imaginetime.com",
        "cpabhm.imaginetime.com",
        "tqportal.imaginetime.com",
        "ikardcpa.imaginetime.com",
        "owenscpa.imaginetime.com",
        "hrgcpas.imaginetime.com",
        "certaintax.imaginetime.com",
        "finecpas.imaginetime.com",
        "payrollxpress.imaginetime.com",
        "boycehund.imaginetime.com",
        "fullercpa.imaginetime.com",
        "rrfcpa.imaginetime.com",
        "ksga.imaginetime.com",
        "hayrice.imaginetime.com",
        "courtney.imaginetime.com",
        "peytonassociates.imaginetime.com",
        "bluefishgroup.imaginetime.com",
        "tadaccounting.imaginetime.com",
        "agasitax.imaginetime.com",
        "vgcpas.imaginetime.com",
        "schellandassociates.imaginetime.com",
        "ahlbeck.imaginetime.com",
        "donnakferrellcpa.imaginetime.com",
        "aprfreellc.imaginetime.com",
        "capstonecpas.imaginetime.com",
        "mhkca.imaginetime.com",
        "kacpapa.imaginetime.com",
        "hillanddavis.imaginetime.com",
        "valborrego.imaginetime.com",
        "proppchristensencaniglia.imaginetime.com",
        "palicpas.imaginetime.com",
        "jdacpa.imaginetime.com",
        "crisellcpa.imaginetime.com",
        "cpakmm.imaginetime.com",
        "cvp.imaginetime.com",
        "danferrari.imaginetime.com",
        "edkburtonllc.imaginetime.com",
        "kahlecpa.imaginetime.com",
        "dsaccounting.imaginetime.com",
        "accountabilityspecialists.imaginetime.com",
        "ftkcpa.imaginetime.com",
        "schoedel.imaginetime.com",
        "daltonleombruno.imaginetime.com",
        "cpa-sh.imaginetime.com",
        "stoutkaiser.imaginetime.com"
    ],
    "cluster3": [
        "petersoncpamt.imaginetime.com",
        "sagerswisher.imaginetime.com",
        "sims-architects.imaginetime.com",
        "mandelladvisorygroup.imaginetime.com",
        "starrcpachicago.imaginetime.com",
        "wardcocpas.imaginetime.com",
        "stratuscpa.imaginetime.com",
        "seelymullins.imaginetime.com",
        "vickiclarkpc.imaginetime.com",
        "mcdougalcpas.imaginetime.com",
        "colodnyfields.imaginetime.com",
        "henrywaters.imaginetime.com",
        "reliablecounts.imaginetime.com",
        "normancpa.imaginetime.com",
        "jarnoldcpa.imaginetime.com",
        "montoyacpa.imaginetime.com",
        "clarencevvettercpa.imaginetime.com",
        "harmeningcpa.imaginetime.com",
        "berkowschechter.imaginetime.com",
        "jarrettandnorton.imaginetime.com",
        "riseagainpheonix.imaginetime.com",
        "lobashaccounting.imaginetime.com",
        "mwadecpa.imaginetime.com",
        "hailecpa.imaginetime.com",
        "northupcpa.imaginetime.com",
        "vastardis.imaginetime.com",
        "empireconsult.imaginetime.com",
        "lakewylietax.imaginetime.com",
        "taxcomplex.imaginetime.com",
        "hartwellcompany.imaginetime.com",
        "pybuscpa.imaginetime.com",
        "vanguardnaples.imaginetime.com",
        "jchucpa.imaginetime.com",
        "jacobsen.imaginetime.com",
        "dellapostacpa.imaginetime.com",
        "ddrcpas.imaginetime.com",
        "pccsc.imaginetime.com",
        "johnsonandassociates.imaginetime.com",
        "spectrumcpa.imaginetime.com",
        "grubbprint.imaginetime.com",
        "dollinscpa.imaginetime.com",
        "nevillsco.imaginetime.com",
        "midnightoil.imaginetime.com",
        "judykeaton.imaginetime.com",
        "tchcpa.imaginetime.com",
        "singlewideaccounting.imaginetime.com",
        "gossbookkeeping.imaginetime.com",
        "mbegcpas.imaginetime.com",
        "heljanassoc.imaginetime.com",
        "brunstcpa.imaginetime.com",
        "yeomansaccounting.imaginetime.com",
        "nlacpa.imaginetime.com",
        "popkincpa.imaginetime.com",
        "willhoppercpa.imaginetime.com",
        "ramercer.imaginetime.com",
        "garywleecpa.imaginetime.com",
        "nygrenaccounting.imaginetime.com",
        "dinkelcpa.imaginetime.com",
        "ryanaccounting.imaginetime.com",
        "mccordcpa.imaginetime.com",
        "cervettalapham.imaginetime.com",
        "simmonstax.imaginetime.com",
        "deckermantax.imaginetime.com",
        "harlancpa.imaginetime.com",
        "chadtestfirm.imaginetime.com",
        "missiontaxkc.imaginetime.com",
        "g4cpa.imaginetime.com",
        "knudtsoncpas.imaginetime.com",
        "jackskehan.imaginetime.com",
        "straightline.imaginetime.com",
        "ew-cpa.imaginetime.com",
        "wesleycurtis.imaginetime.com",
        "yasuda-nakayama.imaginetime.com",
        "avirtualcpafirm.imaginetime.com",
        "orlandocpa.imaginetime.com",
        "purserandassociates.imaginetime.com",
        "cqk.imaginetime.com",
        "gordon-mccurley.imaginetime.com",
        "landoncscott.imaginetime.com",
        "rpitax.imaginetime.com",
        "jmwkc.imaginetime.com",
        "chadtestfirm2.imaginetime.com",
        "wallacetaxes.imaginetime.com",
        "bhbmlaw.imaginetime.com",
        "calipermedia.imaginetime.com",
        "vaaccounting.imaginetime.com",
        "love-cpa.imaginetime.com",
        "schaworthcpa.imaginetime.com",
        "rosenbloomandbutlercpa.imaginetime.com",
        "gellerlehmann.imaginetime.com",
        "heinzjolles.imaginetime.com",
        "schearandschear.imaginetime.com",
        "jeffreystrauss.imaginetime.com",
        "fortisaccounting.imaginetime.com",
        "burgessgroup.imaginetime.com",
        "mtbsinc.imaginetime.com",
        "mbergeron.imaginetime.com",
        "erdhcpa.imaginetime.com",
        "leaders-online.imaginetime.com",
        "turnerbusiness.imaginetime.com"
    ],
    "cluster4": [
        "huntleysigmon.imaginetime.com",
        "qualitybookkeepingservices.imaginetime.com",
        "mcurrycpa.imaginetime.com",
        "muckalawerhancpas.imaginetime.com",
        "matzcpa.imaginetime.com",
        "lmcocpa.imaginetime.com",
        "pwbeancounters.imaginetime.com",
        "ppfg.imaginetime.com",
        "ennsandassociates.imaginetime.com",
        "blscpafirm.imaginetime.com",
        "alans.imaginetime.com",
        "cpacramer.imaginetime.com",
        "eclarkcpa.imaginetime.com",
        "accountvision.imaginetime.com",
        "perpetualcpa.imaginetime.com",
        "ddacpas.imaginetime.com",
        "legacyservicesllc.imaginetime.com",
        "viptaxservices.imaginetime.com",
        "mayclincpa.imaginetime.com",
        "128cpa.imaginetime.com",
        "murphywarren.imaginetime.com",
        "penrodtax.imaginetime.com",
        "rachelsmithcpa.imaginetime.com",
        "hrtaxservices.imaginetime.com",
        "advantagetax.imaginetime.com",
        "walkerco-cpa.imaginetime.com",
        "pandacpas.imaginetime.com",
        "unitedais.imaginetime.com",
        "pinncpas.imaginetime.com",
        "twp.imaginetime.com",
        "leecpas.imaginetime.com",
        "crossroadscpas.imaginetime.com",
        "frickeandassociates.imaginetime.com",
        "fernandezferran.imaginetime.com",
        "jjca.imaginetime.com",
        "lapigman.imaginetime.com",
        "sataxid.imaginetime.com",
        "glucksman.imaginetime.com",
        "thefinancialadvocates.imaginetime.com",
        "venturefinancialgrp.imaginetime.com",
        "dgkochcpa.imaginetime.com",
        "taxstrategists.imaginetime.com",
        "foxassociatescpa.imaginetime.com",
        "jameslkentcpa.imaginetime.com",
        "whansencpa.imaginetime.com",
        "wecpa.imaginetime.com",
        "mgivenscpa.imaginetime.com",
        "waterfrontcpa.imaginetime.com",
        "robertmckinleycpa.imaginetime.com",
        "annehollidaycpa.imaginetime.com",
        "hickshicksbraun.imaginetime.com",
        "sieverslane.imaginetime.com",
        "rvjassociates.imaginetime.com",
        "vucpa.imaginetime.com",
        "cbms.imaginetime.com",
        "fergusontimar.imaginetime.com",
        "digney.imaginetime.com",
        "burkettcpafirm.imaginetime.com",
        "greatoakcpa.imaginetime.com",
        "gomeladvisors.imaginetime.com",
        "scottgaileycpa.imaginetime.com",
        "acme.imaginetime.com",
        "yaegertreviso.imaginetime.com",
        "rodgerscpa.imaginetime.com",
        "ronhawscpa.imaginetime.com",
        "integrityhr.imaginetime.com",
        "fullertaxservice.imaginetime.com",
        "kccompany.imaginetime.com",
        "chrisoconnorcpa.imaginetime.com",
        "ezzyassociates.imaginetime.com",
        "sfs-cpa.imaginetime.com",
        "jklawpa.imaginetime.com",
        "kirksumma.imaginetime.com",
        "temple-allen.imaginetime.com",
        "vpcpas.imaginetime.com",
        "swcpa.imaginetime.com",
        "daviscpafirm.imaginetime.com",
        "actcpa.imaginetime.com",
        "falloncpa.imaginetime.com",
        "yourconroecpa.imaginetime.com",
        "mljcpapa.imaginetime.com",
        "wck-cpa.imaginetime.com",
        "consilium.imaginetime.com",
        "wsa-cpa.imaginetime.com",
        "myidahocpa.imaginetime.com",
        "wajuliancpa.imaginetime.com",
        "kmd89.imaginetime.com",
        "stuarttanimoto.imaginetime.com",
        "jgdickson.imaginetime.com",
        "templecpa.imaginetime.com",
        "experttaxes.imaginetime.com",
        "sscpa.imaginetime.com",
        "timesolv.imaginetime.com",
        "mvatax.imaginetime.com",
        "sfscpas.imaginetime.com",
        "fleurysingler.imaginetime.com",
        "itas4u.imaginetime.com",
        "rptxcpa.imaginetime.com",
        "rbscpa.imaginetime.com",
        "acctworkllc.imaginetime.com"
    ],
    "cluster5": [
        "esevans.imaginetime.com",
        "bastamron.imaginetime.com",
        "lltcpa.imaginetime.com",
        "pricecomin.imaginetime.com",
        "kwaccountingservices.imaginetime.com",
        "rocketmatter2.imaginetime.com",
        "otb-consulting.imaginetime.com",
        "mtcpasllc.imaginetime.com",
        "gallerosrobinson.imaginetime.com",
        "jewartcpa.imaginetime.com",
        "trustconsultinggroup.imaginetime.com",
        "anchorbanc.imaginetime.com",
        "nwpremier.imaginetime.com",
        "porterlasiewicz.imaginetime.com",
        "odellandcritchley.imaginetime.com",
        "lodovicotaxpros.imaginetime.com",
        "forrestermorgan.imaginetime.com",
        "ntegrityfinancial.imaginetime.com",
        "aegarcia.imaginetime.com",
        "walshco.imaginetime.com",
        "mltaccountingservices.imaginetime.com",
        "botetourtcpas.imaginetime.com",
        "jloefflercpa.imaginetime.com",
        "mhoffmancpa.imaginetime.com",
        "ifs.imaginetime.com",
        "cornandcorn.imaginetime.com",
        "paulhickscpa.imaginetime.com",
        "eppingcpas.imaginetime.com",
        "sosbusinessservices.imaginetime.com",
        "chacpa.imaginetime.com",
        "gailperrycpa.imaginetime.com",
        "cherylcpa.imaginetime.com",
        "rsgacct.imaginetime.com",
        "hollandandreilly.imaginetime.com",
        "michaelsclayton.imaginetime.com",
        "cpacharleston.imaginetime.com",
        "ballantynetax.imaginetime.com",
        "taxn8r.imaginetime.com",
        "castletaxsolutions.imaginetime.com",
        "lobcpa.imaginetime.com",
        "grcpa.imaginetime.com",
        "jmodica.imaginetime.com",
        "jpalcpa.imaginetime.com",
        "roncurrycpa.imaginetime.com",
        "trivalleytax.imaginetime.com",
        "shaffercpa.imaginetime.com",
        "hingstonmillerhingston.imaginetime.com",
        "freddicpas.imaginetime.com",
        "blumekeeney.imaginetime.com",
        "expaccounting.imaginetime.com",
        "alandenniscpa.imaginetime.com",
        "atomaccounting.imaginetime.com",
        "whalwyattcpa.imaginetime.com",
        "batportal.imaginetime.com",
        "palmerbradycpa.imaginetime.com",
        "ramirez-cpa.imaginetime.com",
        "adamssbr.imaginetime.com",
        "vhhm.imaginetime.com",
        "connectionmodel.imaginetime.com",
        "brhllc.imaginetime.com",
        "apisbiz.imaginetime.com",
        "soutparkval.imaginetime.com",
        "rpsmithcpa.imaginetime.com",
        "nileclark.imaginetime.com",
        "nagesh-cpa.imaginetime.com",
        "wellingtoncpa.imaginetime.com",
        "kaleedscpa.imaginetime.com",
        "brwc.imaginetime.com",
        "saacpa.imaginetime.com",
        "pelcpa.imaginetime.com",
        "prescottcpa.imaginetime.com",
        "jholmescpa.imaginetime.com",
        "schaeffersam.imaginetime.com",
        "mayottedesign.imaginetime.com",
        "debloisfinancial.imaginetime.com",
        "fisherassociates.imaginetime.com",
        "lauragray.imaginetime.com",
        "marklowhorn.imaginetime.com",
        "rtoc.imaginetime.com",
        "mtronconecpa.imaginetime.com",
        "silverlinecpa.imaginetime.com",
        "bandlcpas.imaginetime.com",
        "wurdtax.imaginetime.com",
        "a-ccpa.imaginetime.com",
        "jamespostmacpa.imaginetime.com",
        "sgcaccounting.imaginetime.com",
        "wfbodie.imaginetime.com",
        "ptbs.imaginetime.com",
        "sayle-sandifer.imaginetime.com",
        "jekale.imaginetime.com",
        "michaelkwhitecpa.imaginetime.com",
        "jhmcpas.imaginetime.com",
        "sharpecompany.imaginetime.com",
        "tauruscpas.imaginetime.com",
        "andrewjonescpa.imaginetime.com",
        "garibiancpa.imaginetime.com",
        "abbottaxservice.imaginetime.com",
        "dccpasfirm.imaginetime.com",
        "low-tax.imaginetime.com",
        "bprcpa.imaginetime.com"
    ],
    "cluster6": [
        "fhllp.imaginetime.com",
        "m2financial.imaginetime.com",
        "gjbcpafirm.imaginetime.com",
        "carrick.imaginetime.com",
        "davincitax.imaginetime.com",
        "fievezbean.imaginetime.com",
        "masonrich.imaginetime.com",
        "mediaquest.imaginetime.com",
        "scimemicpa.imaginetime.com",
        "smithnaleandco-cpas.imaginetime.com",
        "familylawnow.imaginetime.com",
        "northshoreaccounting.imaginetime.com",
        "rohncpagroup.imaginetime.com",
        "smb.imaginetime.com",
        "cpapros.imaginetime.com",
        "barbcpa.imaginetime.com",
        "rothspellman.imaginetime.com",
        "carlastax.imaginetime.com",
        "WSWray.imaginetime.com",
        "vmdcpa.imaginetime.com",
        "watler.imaginetime.com",
        "sblcpas.imaginetime.com",
        "susanragercpa.imaginetime.com",
        "sullivan-law.imaginetime.com",
        "bbicpa.imaginetime.com",
        "wardandmeyerscpa.imaginetime.com",
        "manzolawgroup.imaginetime.com",
        "efileadvantage.imaginetime.com",
        "mansourilawoffices.imaginetime.com",
        "carolynhallisey.imaginetime.com",
        "by-cpas.imaginetime.com",
        "smithdunn.imaginetime.com",
        "venturetax.imaginetime.com",
        "fjlamb.imaginetime.com",
        "michammcswaincpa.imaginetime.com",
        "sycpa.imaginetime.com",
        "hp-cpas.imaginetime.com",
        "rbk-cpa.imaginetime.com",
        "lawmdm.imaginetime.com",
        "vanndixoncpa.imaginetime.com",
        "morrisfirmllc.imaginetime.com",
        "krimlandau.imaginetime.com",
        "balancedbooksbybrenda.imaginetime.com",
        "woodwardassociates.imaginetime.com",
        "grabercpa.imaginetime.com",
        "balancingactfinancials.imaginetime.com",
        "cozzensco.imaginetime.com",
        "wilkinsonwolfecpa.imaginetime.com",
        "schneiderfinancialgroup.imaginetime.com",
        "jmbookkeeper.imaginetime.com",
        "feltham-associates.imaginetime.com",
        "kandmbookeeping.imaginetime.com",
        "protaxinc.imaginetime.com",
        "tabs3prod.imaginetime.com",
        "kherreracpa.imaginetime.com",
        "cortsmall.imaginetime.com",
        "jeremycraigconsulting.imaginetime.com",
        "clvalue.imaginetime.com",
        "vgwcpa.imaginetime.com",
        "dittmerco.imaginetime.com",
        "k4tsolutions.imaginetime.com",
        "kbadvisors.imaginetime.com",
        "authentextsolutions.imaginetime.com",
        "firstmttitle.imaginetime.com",
        "tabs3dev.imaginetime.com",
        "simplifytax.imaginetime.com",
        "bpaccountants.imaginetime.com",
        "pearsoncpa.imaginetime.com",
        "ddcpas.imaginetime.com",
        "stmcpas.imaginetime.com",
        "cpaosk.imaginetime.com",
        "richman-acct.imaginetime.com",
        "hiltoncpa.imaginetime.com",
        "jhwcpas.imaginetime.com",
        "davidbrayea.imaginetime.com",
        "robertbeilstein.imaginetime.com",
        "vwacct.imaginetime.com",
        "marlercpa.imaginetime.com",
        "wlstarkco.imaginetime.com",
        "meadecpa.imaginetime.com",
        "hgcas.imaginetime.com",
        "davisgrouppa.imaginetime.com",
        "cpasrq.imaginetime.com",
        "lazenby-law.imaginetime.com",
        "cianifinancial.imaginetime.com",
        "sherricpa.imaginetime.com",
        "fermannco.imaginetime.com",
        "wendysaccounting.imaginetime.com",
        "cpaelpaso.imaginetime.com",
        "suzisperry.imaginetime.com",
        "fracassicpa.imaginetime.com",
        "hbccpas.imaginetime.com",
        "cprussocpa.imaginetime.com",
        "orangewoodlaw.imaginetime.com",
        "arehartcpa.imaginetime.com",
        "hdhadvisorsllc.imaginetime.com",
        "numbersdontlie.imaginetime.com",
        "beiercpa.imaginetime.com",
        "tarwaterco.imaginetime.com"
    ],
    "cluster7": [
        "scottfrickcpa.imaginetime.com",
        "joemtuckercpa.imaginetime.com",
        "marlinpetersoncpa.imaginetime.com",
        "stallingsandassociates.imaginetime.com",
        "arswealth.imaginetime.com",
        "theforgegroup.imaginetime.com",
        "irmacpa.imaginetime.com",
        "odshraderpc.imaginetime.com",
        "nocomediation.imaginetime.com",
        "hamilton.imaginetime.com",
        "ebocpa.imaginetime.com",
        "jessup-group.imaginetime.com",
        "mydefenselawyers.imaginetime.com",
        "jennifermongeaucpa.imaginetime.com",
        "rcorsicpa.imaginetime.com",
        "ecjtax.imaginetime.com",
        "donnellymilnercpa.imaginetime.com",
        "sevensummits.imaginetime.com",
        "vickeysbookkeeping.imaginetime.com",
        "hrtaxadvisors.imaginetime.com",
        "bcbcpa.imaginetime.com",
        "rspowell.imaginetime.com",
        "dcwcpa.imaginetime.com",
        "frankcamposcpa.imaginetime.com",
        "adminofficeservices.imaginetime.com",
        "gatecas.imaginetime.com",
        "afwcpas.imaginetime.com",
        "app.imaginetime.com",
        "app.lexshare.io",
        "cpamcbride.imaginetime.com",
        "bfadvisors.imaginetime.com",
        "andersen.imaginetime.com",
        "skleinco.imaginetime.com",
        "figercpa.imaginetime.com"
    ]
}

export const appClientIds = {
    cluster1: "753e8da2-a4e8-4512-8af0-5ec4e09f7f9c",
    cluster2: "59474b05-12ff-4835-ba8a-990539d319c4",
    cluster3: "e96755b6-c48d-4391-b0df-1f7675af03b3",
    cluster4: "8debb344-1e13-4fcb-85d2-9c9a967e973e",
    cluster5: "c923aada-c105-44ce-b830-3aad57a4ce1c",
    cluster6: "ac62217d-f7ef-4e6e-8fd3-c9f7feb1a961",
    cluster7: "8942a3de-44db-4138-ab90-75dbe279dce8",
    default: "81e9763a-053f-49a8-89ff-46ff4693e2e3",
}