// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import FilterBy from '../../../../global/components/helpers/FilterBy.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'
import DisplayAsButtons from '../../../../global/components/helpers/DisplayAsButtons.js.jsx'
import MobileActionsOption from '../../../../global/components/helpers/MobileActionOptions.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import AlertModal from '../../../../global/components/modals/AlertModal.js.jsx'

// import actions
import * as staffActions from '../../staffActions'

// import resource components
import PracticeStaffTableListItem from './PracticeStaffTableListItem.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import SelectFromArray from '../../../../global/components/forms/SelectFromArray.js.jsx'
import SelectFromObject from '../../../../global/components/forms/SelectFromObject.js.jsx'

class PracticeStaffList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      viewingAs: this.props.viewingAs,
      selectedStaff: '',
      sendingInvite: false,
      sendingReset: false,
      successInvite: false,
      successReset: false,
      showMobileActionOption: false,
      isDelModalOpen: false,
      searchHeaderColumns: {
        staffName: { disableSearch: true, searchText: '' },
        staffPermission: { disableSearch: true, searchText: '' },
        staffStatus: { disableSearch: true, searchText: '' },
      },
      keepStaffFolder: false,
    }
    this._bind(
      '_handleSelectedTagsChange',
      '_handleResendInvite',
      '_handleResetPassword',
      '_handleMobileOption',
      '_handleFilter',
      '_toggleHeaderSearch',
      '_changeHeaderSearch'
    )
  }

  componentWillUnmount() {
    this.setState({
      searchHeaderColumns: {
        staffName: { disableSearch: true, searchText: '' },
        staffPermission: { disableSearch: true, searchText: '' },
        staffStatus: { disableSearch: true, searchText: '' },
      },
    })
    this.props.dispatch(staffActions.setHeaderFilter({}))
  }

  _handleSelectedTagsChange(e) {
    // additional logic here if we want to break out tags into multiple filters, ie years
    // for now e.target.value contains all of the filters, but may only contain a subset
    // the output to the parent should be the entire list of tags
    this.props.handleFilter(e)
  }

  _handleResendInvite(user, owner) {
    const { dispatch, match } = this.props

    this.setState({ selectedStaff: user._id, sendingInvite: true })

    const sendData = {
      invitations: [
        {
          email: user.username,
          fullName: user.firstname + ' ' + user.lastname,
          owner: owner,
        },
      ],
      personalNote: '',
    }

    const firmId = match.params.firmId

    try {
      dispatch(staffActions.sendInviteStaff(firmId, sendData)).then(
        staffRes => {
          if (staffRes.success) {
            this.setState({ sendingInvite: false, successInvite: true })
            setTimeout(() => {
              this.setState({ successInvite: false })
            }, 2000)
          } else {
            this.setState({ sendingInvite: false, selectedStaff: '' })
            alert('ERROR - Check logs')
          }
        }
      )
    } catch (err) {
      console.error(err)
      this.setState({ sendingInvite: false, selectedStaff: '' })
    }
  }

  _handleResetPassword(user) {
    const { dispatch, match } = this.props

    this.setState({ sendingReset: true, selectedStaff: user._id })

    if (user) {
      const sendData = {
        firmId: match.params.firmId,
        user: user,
      }

      try {
        dispatch(staffActions.sendInviteWithResetUser(sendData)).then(json => {
          if (json.success) {
            this.setState({ sendingReset: false, successReset: true })
            setTimeout(() => {
              this.setState({ successReset: false })
            }, 2000)
          } else {
            this.setState({ sendingReset: false, selectedContact: '' })
          }
        })
      } catch (err) {
        console.error('ERROR', err)
        this.setState({ sendingReset: false, selectedContact: '' })
      }
    }
  }

  _handleMobileOption(e) {
    e.stopPropagation()
    const showMobileActionOption = _.cloneDeep(showMobileActionOption)
    this.setState({ showMobileActionOption: !showMobileActionOption }, () => {
      this.props.handleOptionMenu()
    })
  }

  _handleFilter(sortBy) {
    const { dispatch, staffList } = this.props
    let newFilter = staffList.filter

    if (staffList.filter.sortBy && staffList.filter.sortBy.indexOf('-') < 0) {
      sortBy = '-' + sortBy
    } else {
      sortBy = sortBy.substring(0)
    }
    newFilter.sortBy = sortBy
    dispatch(staffActions.setFilter(newFilter))
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { staffStore, dispatch } = this.props
    const { filterHeaders } = staffStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(staffActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { staffStore, dispatch } = this.props
    const { filterHeaders } = staffStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(staffActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  render() {
    const {
      allTags,
      handleSetPagination,
      match,
      paginatedList,
      selectedTagIds,
      sortedAndFilteredList,
      staffList,
      userMap,
      firmStore,
      handleOptionMenu,
      mobileOptionMenu,
      handleNewStaff,
      sortBy,
      dispatch,
      orderedList,
    } = this.props

    const {
      showMobileActionOption,
      isDelModalOpen,
      selectedStaff,
      searchHeaderColumns,
    } = this.state

    const deleteModal = data => {
      this.setState({ isDelModalOpen: true, selectedStaff: data })
      if (isDelModalOpen) {
        this.setState({ isDelModalOpen: false })
      }
    }

    const deleteStaff = () => {
      dispatch(
        staffActions.sendDelete(selectedStaff._id, this.state.keepStaffFolder)
      )
      this.setState({ isDelModalOpen: false })
    }

    return (
      <div className="file-list-wrapper -list-wrapper-80-yt-col">
        <AlertModal
          alertMessage={
            <div className="flex-col">
              <div className="flex-row">
                <p>
                  Deleting staff{' '}
                  <b>
                    {selectedStaff && selectedStaff.firstname}{' '}
                    {selectedStaff && selectedStaff.lastname}{' '}
                  </b>
                  also deletes their personal folder. Keep the folder?
                </p>
              </div>
              <div className="flex-row">
                <CheckboxInput
                  label="Keep Folder"
                  name="keepStaffFolder"
                  change={v => {
                    this.setState({ keepStaffFolder: v.target.checked })
                  }}
                  value={this.state.keepStaffFolder}
                  checked={this.state.keepStaffFolder}
                />
              </div>
            </div>
          }
          alertTitle="Delete"
          closeAction={deleteModal}
          confirmAction={deleteStaff}
          confirmText={
            this.state.keepStaffFolder
              ? 'Delete staff and keep their folder'
              : 'Delete staff and their folder'
          }
          isOpen={isDelModalOpen}
          type="danger"
        />
        {this.state.viewingAs === 'grid' ? (
          <div className="file-grid">
            {/*
             * TODO: Create a FirmStaffGridListItem
             */}
          </div>
        ) : (
          <table className="yt-table firm-table -workspace-table truncate-cells">
            <caption>
              <PageTabber
                totalItems={orderedList && orderedList.length}
                totalPages={Math.ceil(
                  orderedList && orderedList.length / staffList.pagination.per
                )}
                pagination={staffList.pagination}
                setPagination={handleSetPagination}
                setPerPage={this.props.setPerPage}
                viewingAs="top"
                itemName="staff"
                searchText="Search..."
                firmId={match.params.firmId}
              />
            </caption>
            <thead>
              <TableHeaderColumn
                trClasses=""
                trStyles={{}}
                handleFilter={this._handleFilter}
                filter={sortBy}
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
                searchHeaderColumns={searchHeaderColumns}
              />
            </thead>
            <tbody>
              <TableHeaderColumn
                trClasses="-table-header-mobile-layout"
                trStyles={{ display: 'none' }}
                handleFilter={this._handleFilter}
                filter={sortBy}
                toggleHeaderSearch={this._toggleHeaderSearch}
                changeHeaderSearch={this._changeHeaderSearch}
                searchHeaderColumns={searchHeaderColumns}
              />
              {paginatedList.length > 0 ? (
                paginatedList.map((staff, i) => {
                  if (staff.status === 'deleted') {
                    return
                  }
                  return (
                    <PracticeStaffTableListItem
                      key={'staff_' + staff._id + i}
                      staff={staff}
                      user={userMap[staff._user]}
                      selectedStaff={this.state.selectedStaff}
                      handleResendInvite={this._handleResendInvite}
                      handleResetPassword={this._handleResetPassword}
                      sendingInvite={this.state.sendingInvite}
                      successInvite={this.state.successInvite}
                      sendingReset={this.state.sendingReset}
                      successReset={this.state.successReset}
                      sortBy={sortBy}
                      deleteModal={deleteModal}
                    />
                  )
                })
              ) : (
                <tr className="empty-state">
                  <td>
                    <em>No staff</em>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

const TableHeaderColumn = ({
  trClasses,
  trStyles,
  handleFilter,
  filter,
  toggleHeaderSearch,
  changeHeaderSearch,
  searchHeaderColumns,
}) => {
  return (
    <tr
      className={trClasses}
      style={trStyles}
    >
      <th className="_50">
        <div
          className="-table-header-title"
          onClick={() => handleFilter('staff')}
        >
          Name &nbsp;
          {filter && filter == 'staff' ? (
            <i className="fad fa-sort-down"></i>
          ) : filter && filter == '-staff' ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        <div className="-table-header-search">
          <CheckboxInput
            name="staffName"
            value={!searchHeaderColumns.staffName.disableSearch}
            checked={!searchHeaderColumns.staffName.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => {}}
            change={changeHeaderSearch}
            name="staffName"
            value={searchHeaderColumns.staffName.searchText}
            disabled={searchHeaderColumns.staffName.disableSearch}
            placeholder="Search staff name"
          />
        </div>
      </th>
      <th className="_20">
        <div className="-table-header-title">Permissions</div>
        <div className="-table-header-search -hidden">
          <CheckboxInput
            name="staffPermission"
            value={!searchHeaderColumns.staffPermission.disableSearch}
            checked={!searchHeaderColumns.staffPermission.disableSearch}
            change={toggleHeaderSearch}
          />
          <SelectFromObject
            name="staffPermission"
            items={['Owner', 'Standard']}
            selected={searchHeaderColumns.staffPermission.searchText}
            disabled={searchHeaderColumns.staffPermission.disableSearch}
            change={changeHeaderSearch}
            placeholder="Search permissions"
            menuPlacement="top"
          />
        </div>
      </th>
      <th className="_20">
        <div className="-table-header-title">Status</div>
        <div className="-table-header-search -hidden">
          <CheckboxInput
            name="staffStatus"
            value={!searchHeaderColumns.staffStatus.disableSearch}
            checked={!searchHeaderColumns.staffStatus.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => {}}
            change={changeHeaderSearch}
            name="staffStatus"
            value={searchHeaderColumns.staffStatus.searchText}
            disabled={searchHeaderColumns.staffStatus.disableSearch}
            placeholder="Search status"
          />
        </div>
      </th>
      <th className="_40"></th>
      <th></th>
      <th></th>
    </tr>
  )
}

PracticeStaffList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allTags: PropTypes.array,
  handleFilter: PropTypes.func,
  handleQuery: PropTypes.func,
  handleOpenAddStaffModal: PropTypes.func,
  handleSetPagination: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  selectedTagIds: PropTypes.array,
  sortedAndFilteredList: PropTypes.array,
  staffList: PropTypes.object.isRequired,
  viewingAs: PropTypes.string,
}

PracticeStaffList.defaultProps = {
  allTags: null,
  handleFilter: null,
  handleQuery: null,
  handleOpenAddStaffModal: null,
  handleSort: null,
  sortedAndFilteredList: [],
  viewingAs: 'table',
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  const { staffList, staffListItems, userMap } = props
  const staffStore = store.staff
  let paginatedList = []
  let orderedList = []
  let sortBy = ''

  const newData = []
  const staffName =
    staffStore.filterHeaders &&
    !!staffStore.filterHeaders.staffName &&
    staffStore.filterHeaders.staffName.trim()
  staffListItems.forEach(data => {
    const user = userMap[data._user]
    const fullName = user && user.firstname + ' ' + user.lastname
    let showMe = true

    if (staffName && fullName) {
      showMe = fullName.toLowerCase().indexOf(staffName.toLowerCase()) > -1
    }

    if (!showMe && staffName && user.username) {
      showMe = user.username.toLowerCase().indexOf(staffName.toLowerCase()) > -1
    }

    if (showMe) {
      newData.push({
        firstname: user && user.firstname,
        lastname: user && user.lastname,
        apiKey: data.apiKey,
        apiUsername: data.apiUsername,
        contextUsername: data.contextUsername,
        created_at: data.created_at,
        eSigAccess: data.eSigAccess,
        eSigEmail: data.eSigEmail,
        legacyPermission: data.legacyPermission,
        owner: data.owner,
        status: data.status,
        updated_at: data.updated_at,
        _eSigGrantedBy: data._eSigGrantedBy,
        _firm: data._firm,
        _id: data._id,
        _user: data._user,
      })
    }
  })

  if (staffListItems) {
    const pagination = staffList.pagination || { page: 1, per: 50 }

    sortBy = staffList.filter ? staffList.filter.sortBy : 'staff'

    // SORT THE LIST
    switch (sortBy) {
      case 'staff':
        orderedList = _.orderBy(newData, [item => item.firstname], ['asc'])
        break
      case '-staff':
        orderedList = _.orderBy(newData, [item => item.firstname], ['desc'])
        break
      default:
        orderedList = newData
    }
    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(orderedList, start, end)
  }

  return {
    paginatedList: paginatedList,
    staffMap: store.staff.byId,
    userMap: store.user.byId,
    firmStore: store.firm,
    sortBy,
    staffStore: store.staff,
    orderedList,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeStaffList))
