/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import moment from 'moment';
import { DateTime } from 'luxon'

// import actions
import * as requestTaskActions from '../requestTaskActions'
import * as activityActions from '../../activity/activityActions'
import * as requestActions from '../../request/requestActions'
import * as requestFolderActions from '../../requestFolder/requestFolderActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import SingleDatePickerInput from '../../../global/components/forms/dates/SingleDatePickerInput.js.jsx'
import FileLocation from '../../file/components/FileLocation.js.jsx'
import UserInput from '../../../global/components/forms/UsersInput.js.jsx'
import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

// import components

class RequestTaskForm extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      request: {},
      requestTask: {
        dueDate: DateTime.local().toMillis(),
        assignee: [],
      },
      submitting: false,
      field: {
        category: false,
        dueDate: false,
        description: false,
        assignee: false,
        _folder: false,
      },
      selectedFolder: {
        _client: props.match.params.clientId,
      },
      assignees: [
        {
          firstname: '',
          lastname: '',
          username: '',
        },
      ],
    }
    this._bind(
      '_handleFormChange',
      '_handleAddAssignee',
      '_handleCreateRequestTask',
      '_handleClose',
      '_handleBulkUpdateRequesTask',
      '_handleCheckedField',
      '_handleLocationChange',
      '_handleUserChange',
      '_handleRemoveUser'
    )
  }

  componentDidMount() {}

  componentWillReceiveProps(prevProps) {}

  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleAddAssignee() {
    const assignees = _cloneDeep(this.state.assignees)
    assignees.push({
      firstname: '',
      lastname: '',
      username: '',
    })
    this.setState({ assignees })
  }

  _handleCreateRequestTask() {
    const { dispatch, match, listArgs, selectedClient } = this.props
    const requestTask = _cloneDeep(this.state.requestTask)
    const selectedFolder = _cloneDeep(this.state.selectedFolder)
    const assignees = _cloneDeep(this.state.assignees)

    let listArgsCopy = _cloneDeep(listArgs) || {}

    this.setState({ submitting: true })
    requestTask._request = match.params.requestId
    requestTask._firm = match.params.firmId
    requestTask._request = match.params.requestId
    requestTask._folder = selectedFolder && selectedFolder._id

    if (selectedClient._id) {
      requestTask._client = selectedClient._id
    }

    if (assignees && assignees.length) {
      assignees.map(({ emailError, ...item }) => {
        if (item && item.firstname && item.lastname && item.username) {
          requestTask.assignee.push(item)
        }
      })
    }

    dispatch(requestTaskActions.sendCreateRequestTask(requestTask)).then(
      json => {
        if (json.success) {
          dispatch(
            requestActions.fetchSingleRequestById(match.params.requestId)
          )
          dispatch(requestTaskActions.addSingleRequestTaskToMap(json.item))
          dispatch(
            requestTaskActions.addRequestTaskToList(json.item, ...listArgsCopy)
          )
          dispatch(requestFolderActions.singleUpdateToMap(json.requestFolder))
          dispatch(requestActions.singleUpdateToMap(json.request))
          if (json.activity && selectedClient._id) {
            dispatch(
              activityActions.invalidateList(...['_client', selectedClient._id])
            )
          }
          this._handleClose()
        } else {
          this.setState({ submitting: false })
          alert(json.error)
        }
      }
    )
  }

  _handleClose() {
    this.setState(
      {
        requestTask: { dueDate: DateTime.local().toMillis() },
        submitting: false,
        field: {
          category: false,
          dueDate: false,
          description: false,
          assignee: false,
          _folder: false,
        },
      },
      () => {
        this.props.close()
      }
    )
  }

  _handleBulkUpdateRequesTask() {
    const { dispatch, match, selectedTaskIds } = this.props
    const requestTask = _cloneDeep(this.state.requestTask)
    const assignees = _cloneDeep(this.state.assignees)
    const field = _cloneDeep(this.state.field)

    this.setState({ submitting: true })

    requestTask.dueDate = new Date(requestTask.dueDate)

    if (!field.category) {
      delete requestTask.category
    }
    if (!field.dueDate) {
      delete requestTask.dueDate
    }
    if (!field.description) {
      delete requestTask.description
    }
    if (!field.assignee) {
      delete requestTask.assignee
    } else {
      requestTask.assignee = []
      assignees.map(({ emailError, ...item }) => {
        if (item && item.firstname && item.lastname && item.username) {
          requestTask.assignee.push(item)
        }
      })
    }
    if (!field._folder) {
      delete requestTask._folder
    }

    const sendData = {
      requestTasksIds: selectedTaskIds,
      requestTask,
      _firm: match.params.firmId,
      _client: match.params.clientId,
      field,
    }

    dispatch(requestTaskActions.sendBulkUpdateRequestTask(sendData)).then(
      json => {
        if (json.success) {
          this._handleClose()
        } else {
          this.setState({ submitting: false })
          alert(json.error)
        }
      }
    )
  }

  _handleCheckedField(name) {
    const field = _cloneDeep(this.state.field)
    field[name] = !field[name]
    this.setState({ field })
  }

  _handleLocationChange(folder) {
    this.setState({ selectedFolder: folder })
  }

  _handleUserChange(e) {
    const { userMap } = this.props

    const user = userMap && e.target.value && userMap[e.target.value]
    if (user && user._id) {
      const assignee = {
        _id: user._id,
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
      }
      let newState = _update(_cloneDeep(this.state), e.target.name, () => {
        return assignee
      })
      this.setState(newState)
    }
  }

  _handleRemoveUser(index) {
    const assignees = _cloneDeep(this.state.assignees)
    delete assignees[index]
    this.setState({ assignees: assignees.filter(item => item) })
  }

  render() {
    const {
      isOpen,
      selectedClient = {},
      clientUserListItems = [],
      selectedRequestTask,
      isBulkEditMode,
      match,
    } = this.props

    const {
      submitting,
      requestTask = {},
      field,
      selectedFolder,
      assignees,
    } = this.state

    const modalHeaderText = isBulkEditMode
      ? 'Update Request Tasks'
      : 'Create Request Task'
    const modalConfirmtext = submitting
      ? isBulkEditMode
        ? 'Updating...'
        : 'Creating...'
      : isBulkEditMode
      ? 'Update Request Tasks'
      : 'Create Request Task'
    let disabledButton =
      !(
        requestTask.category &&
        requestTask.dueDate &&
        requestTask.description
      ) || assignees.some(assignee => assignee.emailError)

    if (isBulkEditMode) {
      disabledButton = false
      if (
        field.category ||
        field.dueDate ||
        field.description ||
        field.assignee ||
        field._folder
      ) {
        if (field.assignee && assignees.some(assignee => assignee.emailError)) {
          disabledButton = true
        } else if (
          field.category &&
          !(
            requestTask &&
            requestTask.category &&
            requestTask.category.trim().length
          )
        ) {
          disabledButton = true
        } else if (field.dueDate) {
          disabledButton = false
        } else if (
          field.description &&
          !(
            requestTask &&
            requestTask.description &&
            requestTask.description.trim().length
          )
        ) {
          disabledButton = true
        } else if (field._folder || field.assignee) {
          disabledButton = false
        }
      } else {
        disabledButton = true
      }
    }

    return (
      <Modal
        cardSize="large"
        isOpen={isOpen}
        closeAction={this._handleClose}
        closeText="Cancel"
        confirmAction={
          isBulkEditMode
            ? this._handleBulkUpdateRequesTask
            : this._handleCreateRequestTask
        }
        confirmText={modalConfirmtext}
        disableConfirm={submitting || disabledButton}
        modalHeader={modalHeaderText}
        showButtons={true}
      >
        <div>
          <div className="-share-link-configuration">
            <div className="-header">
              <i className="fas fa-eye" /> Link settings
            </div>
            <div className="-body">
              {(selectedClient._id && (
                <div className="-setting yt-row space-between">
                  <div className="-instructions yt-col">
                    {isBulkEditMode ? (
                      <CheckboxInput
                        // disabled={!checked}
                        label="Upload Location"
                        name="requestTask"
                        value={field._folder}
                        change={this._handleCheckedField.bind(this, '_folder')}
                        checked={field._folder}
                      />
                    ) : (
                      <p>
                        <strong>Upload Location</strong>
                      </p>
                    )}
                  </div>
                  <div className="-inputs yt-col">
                    {/* <TextInput
                                            change={this._handleFormChange}
                                            name="requestTask._folder"
                                            placeholder="Enter category"
                                            value={requestTask._folder}
                                            disabled={!field._folder && isBulkEditMode}
                                        /> */}
                    <FileLocation
                      selectedClient={selectedClient}
                      handleLocationChange={this._handleLocationChange}
                      allowCreateFolder={true} // (firm.allowCreateFolder && viewingAs === "portal") || (viewingAs !== "portal")}
                      selectedFolder={selectedFolder}
                      personalId={null}
                      // getDetail={{ type: "workspace", id: selectedClient && selectedClient._id, firmId: match.params.firmId }}
                      getDetail={{
                        type: (selectedClient._id && 'workspace') || 'general',
                        id: selectedClient._id,
                        firmId: match.params.firmId,
                      }}
                      action="move"
                    />
                  </div>
                </div>
              )) ||
                null}
              {(selectedClient._id && <hr />) || null}
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  {isBulkEditMode ? (
                    <CheckboxInput
                      // disabled={!checked}
                      label="Category"
                      name="requestTask"
                      value={field.category}
                      change={this._handleCheckedField.bind(this, 'category')}
                      checked={field.category}
                    />
                  ) : (
                    <p>
                      <strong>
                        Category<sup className="-required">*</sup>
                      </strong>
                    </p>
                  )}
                </div>
                <div className="-inputs yt-col">
                  <TextInput
                    change={this._handleFormChange}
                    name="requestTask.category"
                    placeholder="Enter category"
                    value={requestTask.category}
                    disabled={!field.category && isBulkEditMode}
                  />
                </div>
              </div>
              <hr />
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  {isBulkEditMode ? (
                    <CheckboxInput
                      // disabled={!checked}
                      label="Due Date"
                      name="requestTask"
                      value={field.dueDate}
                      change={this._handleCheckedField.bind(this, 'dueDate')}
                      checked={field.dueDate}
                    />
                  ) : (
                    <p>
                      <strong>
                        Due Date<sup className="-required">*</sup>
                      </strong>
                    </p>
                  )}
                </div>
                <div className="-inputs yt-col">
                  <SingleDatePickerInput
                    anchorDirection="right" // This aligns the calendar drop down to the right side of the date-input. Default is to the left.
                    change={this._handleFormChange}
                    enableOutsideDays={false}
                    initialDate={requestTask.dueDate} // epoch/unix time in milliseconds
                    inputClasses="-right"
                    minDate={
                      !!selectedRequestTask && !!selectedRequestTask.dueDate
                        ? new Date(selectedRequestTask.dueDate).getTime()
                        : DateTime.local().toMillis()
                    }
                    name="requestTask.dueDate"
                    numberOfMonths={1}
                    placeholder="Set due date"
                    disabled={!field.dueDate && isBulkEditMode}
                  />
                </div>
              </div>
              <hr />
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  {isBulkEditMode ? (
                    <CheckboxInput
                      // disabled={!checked}
                      label="Description"
                      name="requestTask"
                      value={field.description}
                      change={this._handleCheckedField.bind(
                        this,
                        'description'
                      )}
                      checked={field.description}
                    />
                  ) : (
                    <p>
                      <strong>
                        Description<sup className="-required">*</sup>
                      </strong>
                    </p>
                  )}
                </div>
                <div className="-inputs yt-col">
                  <TextInput
                    change={this._handleFormChange}
                    name="requestTask.description"
                    placeholder="Enter description"
                    value={requestTask.description}
                    disabled={!field.description && isBulkEditMode}
                  />
                </div>
              </div>
              {(selectedClient._id && <hr />) || null}
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  {isBulkEditMode ? (
                    <CheckboxInput
                      // disabled={!checked}
                      label="Assignee"
                      name="requestTask"
                      value={field.assignee}
                      change={this._handleCheckedField.bind(this, 'assignee')}
                      checked={field.assignee}
                    />
                  ) : (
                    <p>
                      <strong>Assignee</strong>
                    </p>
                  )}
                </div>
                <div className="yt-col">
                  <button
                    className="yt-btn xx-small u-pullRight input-group"
                    onClick={this._handleAddAssignee}
                  >
                    <i className="fal fa-plus" /> Add assignees
                  </button>
                  <div className="input-group">
                    {assignees.map((assignee, i) => {
                      return assignee ? (
                        <UserInput
                          allowSharedEmail={true}
                          change={this._handleFormChange}
                          handleUserChange={this._handleUserChange}
                          currentIndex={i}
                          key={'assignee' + i}
                          userListItems={clientUserListItems}
                          user={assignee}
                          selectedClient={
                            (match.params.clientId && selectedClient) || null
                          }
                          usersId={assignees.flatMap(item =>
                            item._id ? [Number(item._id)] : []
                          )}
                          fieldName="assignees"
                          users={assignees}
                          removeUser={this._handleRemoveUser}
                        />
                      ) : null
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

RequestTaskForm.propTypes = {}

RequestTaskForm.defaultProps = {}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  // const availableStaff = !staffList ? [] : staffList.filter(staff => {
  //     if (staff.status === 'active') {
  //       let item = staff;
  //       let fullName = userStore.byId[staff._user] ? `${userStore.byId[staff._user].firstname} ${userStore.byId[staff._user].lastname}` : '';
  //       let userName = userStore.byId[staff._user] ? userStore.byId[staff._user].username : '';
  //       item.displayName = `${fullName} | ${userName}`;
  //       item.email = userName;
  //       return item;
  //     }
  //   });
  const { selectedUsers = [] } = props
  const clientUserListItems = selectedUsers.map(user => {
    let newUser = _cloneDeep(user)
    if (!user.firstname && !user.lastname) {
      newUser.displayName = user.username
    } else {
      newUser.displayName = `${user.firstname} ${user.lastname}`
    }
    newUser.email = user.username
    return newUser
  })

  return {
    loggedInUser: store.user.loggedIn.user,
    clientUserListItems,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestTaskForm))
