import React, { Component } from 'react'
import AvatarEditor from 'react-avatar-editor'
import '../../../theme/imaginetime/styles/components/_imageCropper.css'

class ImageCropper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scale: 3,
      editorWidth: 300,
      editorHeight: 300,
      editedImage: null,
    }

    this.setEditorRef = this.setEditorRef.bind(this)
    this.handleScale = this.handleScale.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  setEditorRef(editor) {
    this.editor = editor
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value)
    this.setState({ scale }, this.handleSave)
  }

  handleSave() {
    if (this.editor) {
      const editedImage = this.editor.getImage().toDataURL()
      this.setState({ editedImage }, () => {
        if (this.props.onChange) {
          this.props.onChange(editedImage)
        }
      })
    }
  }

  render() {
    return (
      <div className="editor-container">
        <div className="editor-wrapper">
          {this.props.src ? (
            <AvatarEditor
              ref={this.setEditorRef}
              image={this.props.src}
              width={this.state.editorWidth}
              height={this.state.editorHeight}
              border={700}
              scale={this.state.scale}
              style={{
                width: '100%',
                height: '80%',
              }}
              onMouseUp={this.handleSave}
              onLoadSuccess={this.handleSave}
            />
          ) : (
            <div className="placeholder-text">No image selected</div>
          )}
        </div>
        <div className="slider-wrapper">
          <label htmlFor="zoom">Zoom</label>
          <input
            type="range"
            min="1"
            max="10"
            step="0.1"
            name="zoom"
            style={{ width: '100%' }}
            value={this.state.scale}
            onChange={this.handleScale}
          />
        </div>
      </div>
    )
  }
}

export default ImageCropper
