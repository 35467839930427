/**
 * TODO: @ffugly
 * open file preview instead of download link
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import fileUtils from '../../../global/utils/fileUtils'
import Pdftron from '../../../global/components/helpers/Pdftron.jsx'

import PDFViewer from '../../../global/components/helpers/PDFViewer.js.jsx'
import PDFEditor from '../../../global/components/helpers/PDFEditor.js.jsx'

class PreviewFile extends Binder {
  constructor(props) {
    super(props)

    this.state = {}
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if(this.props.file && this.props.file._id && nextProps.file && nextProps.file._id) {
  //     return true;
  //   }
  //   return false;
  // }

  render() {
    const {
      contentType,
      filePath,
      isIE,
      isEditor,
      signers,
      selectedSignerIndex,
      handleSelectedJotblock,
      handleElements,
      handleSignatureStyle,
      handleAttachment,
      file,
      viewingAs,
      googleDocxExntesion,
      currentElement,
      selectedFirm,
      loggedInUser,
      isFetching,
      isSimpleView,
    } = this.props

    const InternetExplorerMessage = (
      <div>
        <span>
          <small>
            If you're using Internet Explorer, right-click the DOWNLOAD button
            and select "Save target as..."
          </small>
        </span>
      </div>
    )
    const fileExtension =
      file && file.fileExtension ? file.fileExtension.toLowerCase() : ''

    if (
      (!isEditor && contentType && contentType.includes('image')) ||
      ['.jpg', '.png', '.jpeg'].includes(fileExtension)
    ) {
      return (
        <div className="-preview-file -image">
          <img src={filePath} />
          {isIE ? InternetExplorerMessage : null}
        </div>
      )
    } else if (
      !isEditor &&
      ((contentType && contentType.includes('video')) ||
        (fileExtension && fileExtension.indexOf('.mp4') > -1) ||
        (fileExtension && fileExtension.indexOf('.mp3') > -1) ||
        (fileExtension && fileExtension.indexOf('.mov') > -1))
    ) {
      return (
        <div className="-preview-file -video">
          <video
            width="100%"
            controls
          >
            <source
              src={filePath}
              type="video/mp4"
            />
          </video>
          {isIE ? InternetExplorerMessage : null}
        </div>
      )
    } else if (
      viewingAs === 'PDFFormat' ||
      (contentType && contentType.includes('pdf')) ||
      ['.pdf'].includes(fileExtension)
    ) {
      let updatedFilePath = filePath
      if (!(loggedInUser && loggedInUser._id)) {
        updatedFilePath += `&sessionToken=${selectedFirm && selectedFirm.apiKey}`
      }
      if (!isSimpleView) {
        return (
          <div className="-preview-file -pdf">
            {isEditor ? (
              <PDFEditor
                ref="pdfEditor"
                filePath={updatedFilePath}
                autoScroll={false}
                onDone={() => null}
                hidden={false}
                signers={signers}
                selectedSignerIndex={selectedSignerIndex}
                handleSelectedJotblock={handleSelectedJotblock}
                handleElements={handleElements}
                handleSignatureStyle={handleSignatureStyle}
                handleAttachment={handleAttachment}
                currentElement={currentElement}
              />
            ) : (
              <PDFViewer
                filePath={updatedFilePath}
                autoScroll={false}
                onDone={() => null}
                hidden={false}
              />
            )}
            {isIE ? InternetExplorerMessage : null}
          </div>
        )
      }
      return (
        <>
          <Pdftron
            filePath={updatedFilePath}
            isFetching={isFetching}
            selectedFile={file}
            isEditMode={false}
          />
          {isIE ? InternetExplorerMessage : null}
        </>
      )
    } else if (
      (googleDocxExntesion && googleDocxExntesion.includes(fileExtension)) ||
      [
        '.doc',
        '.docx',
        '.docm',
        '.xls',
        '.xlsx',
        '.xlsm',
        '.ppt',
        '.pptx',
        '.pptm',
      ].includes(fileExtension)
    ) {
      let updatedFilePath = filePath
      if (!(loggedInUser && loggedInUser._id)) {
        updatedFilePath += updatedFilePath.includes("?") ? `&sessionToken=${selectedFirm && selectedFirm.apiKey}` : `?sessionToken=${selectedFirm && selectedFirm.apiKey}`
      }
      return (
        <Pdftron
          filePath={updatedFilePath}
          isFetching={isFetching}
          selectedFile={file}
          isEditMode={false}
        />
      )
    } else {
      return (
        <div>{`Can't display a preview for "${file && fileExtension ? fileExtension : contentType}" file`}</div>
      )
    }
  }
}

PreviewFile.propTypes = {
  contentType: PropTypes.string.isRequired,
  filePath: PropTypes.string.isRequired,
  isIE: PropTypes.bool,
  iframeKey: PropTypes.number,
  googleDocxExntesion: PropTypes.array,
  isSimpleView: PropTypes.bool,
}

PreviewFile.defaultProps = {
  isIE: false,
  iframeKey: 0,
  isSimpleView: false,
}

export default PreviewFile
