/**
 * View for route /firm/:firmId/contacts
 *
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'
import withRouter from 'react-router-dom/withRouter'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

// import third-party libraries
import { Helmet } from 'react-helmet'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import PageTabber from '../../../../global/components/pagination/PageTabber.js.jsx'
import YTRoute from '../../../../global/components/routing/YTRoute.js.jsx'
import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'

// import utilities
import filterUtils from '../../../../global/utils/filterUtils'
import permissions from '../../../../global/utils/permissions'
import routeUtils from '../../../../global/utils/routeUtils'
import displayUtils from '../../../../global/utils/displayUtils'

// import firm components
import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'

// import resource components
import PracticeContactListItem from '../components/PracticeContactListItem.js.jsx'
import ContactQuickView from './ContactQuickView.js.jsx'

// import actions
import * as addressActions from '../../../address/addressActions'
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../userActions'
import * as phoneNumberActions from '../../../phoneNumber/phoneNumberActions'
import * as clientUserActions from '../../../clientUser/clientUserActions'
import * as clientActions from '../../../client/clientActions'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import ExportList from '../../../../global/components/helpers/ExportList.js.jsx'

class PracticeContactsList extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      per: 50,
      queryText: '',
      sortBy: 'name',
      listArgsObj: {},
      query: '',
      selectedUserIds: [],
      submitResendInvite: false,
      searchHeaderColumns: {
        contactFullName: { disableSearch: true, searchText: '' },
        contactEmail: { disableSearch: true, searchText: '' },
        address: { disableSearch: true, searchText: '' },
        phoneNumber: { disableSearch: true, searchText: '' },
      },
    }
    this._bind(
      '_handleSetPagination',
      '_setPerPage',
      '_handleFilter',
      '_handleQuery',
      '_handleSelectUser',
      '_handleToggleSelectAll',
      '_clearSelectedUserIds',
      '_handleBulkResendInvite',
      '_toggleHeaderSearch',
      '_changeHeaderSearch',
      '_handleResetFilter'
    )
  }

  componentWillUnmount() {
    this._handleResetFilter()
  }

  componentDidMount() {
    const { dispatch, loggedInUser, match, staffStore, location } = this.props
    const query = new URLSearchParams(location.search)
    const page = query.get('page')
    const perPage = query.get('per')
    // get stuff for global nav
    dispatch(firmActions.fetchListIfNeeded('_user', loggedInUser._id))
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id)) // this should live on every top-level route of the portal

    if (loggedInUser.admin) {
      dispatch(
        phoneNumberActions.fetchListIfNeeded('_firm', match.params.firmId)
      )
      dispatch(addressActions.fetchListIfNeeded('_firm', match.params.firmId))
      dispatch(userActions.fetchListIfNeeded('_firm', match.params.firmId))
      dispatch(
        userActions.setFilter(
          { query: '', sortBy: 'contact' },
          '_firm',
          match.params.firmId
        )
      )
      dispatch(
        userActions.setPagination(
          { page: 1, per: 50 },
          '_firm',
          match.params.firmId
        )
      )
    } else {
      dispatch(
        staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId)
      ).then(staffRes => {
        if (staffRes.success) {
          this.setState({ listArgsObj: { _staff: staffRes.item._id } })
          dispatch(
            phoneNumberActions.fetchListIfNeeded('_staff', staffRes.item._id)
          )
          dispatch(
            addressActions.fetchListIfNeeded('_staff', staffRes.item._id)
          )
          dispatch(userActions.fetchListIfNeeded('_staff', staffRes.item._id))
          dispatch(
            userActions.setFilter(
              { query: '', sortBy: 'contact' },
              '_staff',
              staffRes.item._id
            )
          )
          dispatch(
            userActions.setPagination(
              { page: 1, per: 50 },
              '_staff',
              staffRes.item._id
            )
          )
        }
      })
    }

    // get stuff for this view
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId))
    // dispatch(staffClientActions.fetchListIfNeeded('_firm', match.params.firmId, '_user', loggedInUser._id));

    // dispatch(userActions.setQuery('', '_firm', match.params.firmId));
    // dispatch(userActions.setFilter('', '_firm', match.params.firmId));
    if (page) {
      setTimeout(() => {
        this._handleSetPagination({ page: page, per: perPage })
      }, 500)
    } else {
      this._handleSetPagination({ page: 1, per: 50 })
    }
  }

  _setPerPage(per) {
    const { dispatch } = this.props
    let newPagination = this.props.userList.pagination
    newPagination.per = parseInt(per)
    newPagination.page = 1
    this._handleSetPagination(newPagination)
    this.setState({ per: newPagination.per })
  }

  _handleSetPagination(newPagination) {
    const { dispatch } = this.props
    dispatch(userActions.setPagination(newPagination))
  }

  _handleFilter(sortBy) {
    const { dispatch, match, userList } = this.props
    dispatch(
      staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId)
    ).then(staffRes => {
      let newFilter = userList.filter
      if (
        userList &&
        userList.filter &&
        userList.filter.sortBy &&
        userList.filter.sortBy.indexOf('-') < 0
      ) {
        sortBy = '-' + sortBy
      } else {
        sortBy = sortBy.substring(0)
      }
      newFilter.sortBy = sortBy
      dispatch(userActions.setFilter(newFilter, '_staff', staffRes.item._id))
      dispatch(
        userActions.setPagination(
          { page: 1, per: 50 },
          '_staff',
          staffRes.item._id
        )
      )
    })
  }

  _handleQuery(e) {
    const { dispatch } = this.props
    // always defaulting the page to page 1 so we can see our results
    // let pagination = {};
    // pagination.page = 1;
    // pagination.per = this.state.per;
    // this._handleSetPagination(pagination);
    // continue query logic
    dispatch(
      userActions.setQuery(
        e.target.value.toLowerCase(),
        ...routeUtils.listArgsFromObject(this.state.listArgsObj)
      )
    )
    this.setState({ query: e.target.value.toLowerCase() })
  }

  _handleSelectUser(userId) {
    let newUserIds = _.cloneDeep(this.state.selectedUserIds)
    if (newUserIds.indexOf(userId) === -1) {
      newUserIds.push(userId)
    } else {
      newUserIds.splice(newUserIds.indexOf(userId), 1)
    }
    this.setState({
      selectedUserIds: newUserIds,
    })
  }

  _handleToggleSelectAll(paginatedList, allUserSelected) {
    const { selectedUserIds } = this.state
    if (selectedUserIds.length > 0 && allUserSelected) {
      this._clearSelectedUserIds()
    } else if (paginatedList) {
      let newSelectedUserIds = _.cloneDeep(selectedUserIds)
      paginatedList.map(item =>
        newSelectedUserIds.indexOf(item._id) < 0
          ? newSelectedUserIds.push(item._id)
          : null
      )
      this.setState({ selectedUserIds: newSelectedUserIds })
    } else null
  }

  _clearSelectedUserIds() {
    this.setState({
      selectedUserIds: [],
    })
  }

  _handleBulkResendInvite() {
    const { dispatch, match, userMap } = this.props
    const { selectedUserIds } = this.state

    this.setState({ submitResendInvite: true })

    const firmId = match.params.firmId

    const getInvitation = cb => {
      if (selectedUserIds && selectedUserIds.length) {
        const result = selectedUserIds.map(val => userMap[val])
        cb(result)
      } else {
        cb(false)
      }
    }

    getInvitation(invitations => {
      if (invitations) {
        const sendData = {
          invitations: invitations,
          firmId,
        }
        dispatch(clientUserActions.sendResendInviteClientUsers(sendData)).then(
          clientUserRes => {
            if (clientUserRes.success) {
              this.setState({ submitResendInvite: false, selectedUserIds: [] })
            } else {
              this.setState({ submitResendInvite: false })
              alert('ERROR - Check logs')
            }
          }
        )
      } else {
        this.setState({ submitResendInvite: false })
        alert('ERROR - Check logs')
      }
    })
  }

  _toggleHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { userStore, dispatch } = this.props
    const { filterHeaders } = userStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].disableSearch = !e.target.value
    if (searchHeaderColumns[e.target.name].disableSearch) {
      delete newFilterHeaders[e.target.name]
    } else {
      newFilterHeaders[e.target.name] =
        searchHeaderColumns[e.target.name].searchText
    }
    dispatch(userActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _changeHeaderSearch(e) {
    const searchHeaderColumns = _.cloneDeep(this.state.searchHeaderColumns)
    const { userStore, dispatch } = this.props
    const { filterHeaders } = userStore
    let newFilterHeaders = _.cloneDeep(filterHeaders)
    searchHeaderColumns[e.target.name].searchText = e.target.value
    newFilterHeaders[e.target.name] = e.target.value
    dispatch(userActions.setHeaderFilter(newFilterHeaders))
    this.setState({ searchHeaderColumns })
  }

  _handleResetFilter() {
    const dispatch = this.props.dispatch
    this.setState({
      searchHeaderColumns: {
        contactFullName: { disableSearch: true, searchText: '' },
        contactEmail: { disableSearch: true, searchText: '' },
        address: { disableSearch: true, searchText: '' },
        phoneNumber: { disableSearch: true, searchText: '' },
      },
    })
    dispatch(userActions.setHeaderFilter({}))
  }

  render() {
    const {
      location,
      loggedInUser,
      match,
      paginatedList,
      orderedList,
      phoneNumberStore,
      addressStore,
      staffStore,
      userList,
      contacts,
    } = this.props

    const { selectedUserIds, submitResendInvite, searchHeaderColumns } =
      this.state

    const isEmpty = !paginatedList || !userList

    const isFetching = !paginatedList || !userList || userList.isFetching

    const defaultHeaders = [
      {
        label: 'Contact',
        key: 'contactFullName',
      },
      {
        label: 'Email',
        key: 'contactEmail',
      },
      {
        label: 'Phone',
        key: 'phoneNumber',
      },
      {
        label: 'Address',
        key: 'address',
      },
    ]

    const hasExportAccess = permissions.hasExportAccess(
      staffStore,
      match.params.firmId
    )

    const ownerPermissions = permissions.isStaffOwner(
      staffStore,
      loggedInUser,
      match.params.firmId
    )

    const filter = userList && userList.filter && userList.filter.sortBy

    const allUsersSelected = selectedUserIds.length
      ? paginatedList.every(item => selectedUserIds.includes(item._id))
      : false
    //paginatedList = paginatedList.sort((a,b) => a.firstName < b.firstName ? -1 : 1);
    return (
      <PracticeLayout isSidebarOpen={true}>
        <Helmet>
          <title>Contact List </title>
        </Helmet>
        <div className="-practice-subnav">
          <div className="yt-container fluid">
            <div className="yt-row center-vert space-between">
              <Breadcrumbs links={location.state.breadcrumbs} />
            </div>
          </div>
        </div>
        <div className="yt-container fluid">
          {/* <h1>{ ownerPermissions ? 'All ' : 'My '} Client Contacts</h1> */}
          <h1 className="-tab-name">Contacts</h1>
        </div>
        <div className="-practice-content">
          {isEmpty ? (
            isFetching ? (
              <div className="-loading-hero hero">
                <div className="u-centerText">
                  <div className="loading"></div>
                </div>
              </div>
            ) : (
              <div className="hero -empty-hero">
                <div className="u-centerText">
                  <p>Looks like you don't have any contacts yet. </p>
                  <p>Let's add some.</p>
                </div>
              </div>
            )
          ) : (
            <div
              className="yt-container fluid"
              style={{ opacity: isFetching ? 0.5 : 1, height: '200vh' }}
            >
              <div className="yt-toolbar">
                <div className="yt-tools space-between">
                  <div className="-filters -left">
                    <button
                      className="yt-btn x-small"
                      onClick={this._handleResetFilter}
                    >
                      Reset Filter
                    </button>
                    <ExportList
                      data={[...orderedList]}
                      columns={[...defaultHeaders]}
                      gridName={'contacts_list'}
                      hasExportAccess={hasExportAccess}
                    />
                  </div>
                  <div className="-options -right">
                    <button
                      style={{ marginLeft: '0px' }}
                      className="yt-btn x-small link info"
                      disabled={!selectedUserIds.length || submitResendInvite}
                      onClick={this._handleBulkResendInvite}
                    >
                      Resend Invite
                      {selectedUserIds.length ? (
                        <span> — {selectedUserIds.length}</span>
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
              <hr className="-mobile-yt-hide" />
              <div
                className="table-wrapper -practice-table-wrapper"
                style={{ marginTop: 0 }}
              >
                <table className="yt-table user-table-list">
                  <caption>
                    <PageTabber
                      totalItems={contacts.length}
                      totalPages={Math.ceil(
                        contacts.length / userList.pagination.per
                      )}
                      pagination={userList.pagination}
                      setPagination={this._handleSetPagination}
                      setPerPage={this._setPerPage}
                      viewingAs="top"
                      itemName="contacts"
                      handleQuery={this._handleQuery}
                      query={this.state.query}
                      firmId={match.params.firmId}
                    />
                  </caption>
                  <thead>
                    <TableHeaderColumn
                      trClasses=""
                      trStyles={{}}
                      allUsersSelected={allUsersSelected}
                      handleToggleSelectAll={this._handleToggleSelectAll}
                      paginatedList={paginatedList}
                      handleFilter={this._handleFilter}
                      filter={filter}
                      toggleHeaderSearch={this._toggleHeaderSearch}
                      changeHeaderSearch={this._changeHeaderSearch}
                      searchHeaderColumns={searchHeaderColumns}
                    />
                  </thead>
                  <tbody>
                    <TableHeaderColumn
                      trClasses="-table-header-mobile-layout"
                      trStyles={{ display: 'none' }}
                      allUsersSelected={allUsersSelected}
                      handleToggleSelectAll={this._handleToggleSelectAll}
                      paginatedList={paginatedList}
                      handleFilter={this._handleFilter}
                      filter={filter}
                      toggleHeaderSearch={this._toggleHeaderSearch}
                      changeHeaderSearch={this._changeHeaderSearch}
                      searchHeaderColumns={searchHeaderColumns}
                    />
                    {paginatedList.map((user, i) => (
                      <PracticeContactListItem
                        key={user._id + '' + i}
                        user={user}
                        phoneNumber={user.phoneNumber}
                        address={user.address}
                        handleSelectUser={this._handleSelectUser}
                        checked={selectedUserIds.includes(user._id)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="slide-from-right"
                  timeout={300}
                >
                  <Switch location={location}>
                    <YTRoute
                      breadcrumbs={[
                        {
                          display: 'Contacts',
                          path: `/firm/${match.params.firmId}/contacts`,
                        },
                        { display: 'Details', path: null },
                      ]}
                      exact
                      path="/firm/:firmId/contacts/quick-view/:userId"
                      staff={true}
                      component={ContactQuickView}
                    />
                    <Route render={() => <div />} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              {/* <YTRoute path="/firm/:firmId/contacts/quick-view/:userId" login={true} render={() => <h3>modal</h3>}/> */}
            </div>
          )}
        </div>
      </PracticeLayout>
    )
  }
}

const TableHeaderColumn = ({
  trClasses,
  trStyles,
  allUsersSelected,
  handleToggleSelectAll,
  paginatedList,
  handleFilter,
  filter,
  toggleHeaderSearch,
  changeHeaderSearch,
  searchHeaderColumns,
}) => {
  return (
    <tr
      className={trClasses}
      style={trStyles}
    >
      <th>
        <CheckboxInput
          name="user"
          value={allUsersSelected}
          change={() => handleToggleSelectAll(paginatedList, allUsersSelected)}
          checked={allUsersSelected}
        />
      </th>
      <th className="-title sortable _30">
        <div
          className="-table-header-title"
          onClick={() => handleFilter('contact')}
        >
          Contact
          {filter && filter == 'contact' ? (
            <i className="fad fa-sort-down"></i>
          ) : filter && filter == '-contact' ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        <div className="-table-header-search">
          <CheckboxInput
            name="contactFullName"
            value={!searchHeaderColumns.contactFullName.disableSearch}
            checked={!searchHeaderColumns.contactFullName.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="contactFullName"
            value={searchHeaderColumns.contactFullName.searchText}
            disabled={searchHeaderColumns.contactFullName.disableSearch}
            placeholder="Search contact name"
          />
        </div>
      </th>
      <th className="-title sortable _30">
        <div
          className="-table-header-title"
          onClick={() => handleFilter('email')}
        >
          Email
          {filter && filter == 'email' ? (
            <i className="fad fa-sort-down"></i>
          ) : filter && filter == '-email' ? (
            <i className="fad fa-sort-up"></i>
          ) : (
            <i className="fad fa-sort"></i>
          )}
        </div>
        <div className="-table-header-search">
          <CheckboxInput
            name="contactEmail"
            value={!searchHeaderColumns.contactEmail.disableSearch}
            checked={!searchHeaderColumns.contactEmail.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="contactEmail"
            value={searchHeaderColumns.contactEmail.searchText}
            disabled={searchHeaderColumns.contactEmail.disableSearch}
            placeholder="Search contact email"
          />
        </div>
      </th>
      <th className="_20">
        <div className="-table-header-title">Phone Number</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="phoneNumber"
            value={!searchHeaderColumns.phoneNumber.disableSearch}
            checked={!searchHeaderColumns.phoneNumber.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="phoneNumber"
            value={searchHeaderColumns.phoneNumber.searchText}
            disabled={searchHeaderColumns.phoneNumber.disableSearch}
            placeholder="Search phone number"
          />
        </div>
      </th>
      <th className="_30">
        <div className="-table-header-title">Address</div>
        <div className="-table-header-search">
          <CheckboxInput
            name="address"
            value={!searchHeaderColumns.address.disableSearch}
            checked={!searchHeaderColumns.address.disableSearch}
            change={toggleHeaderSearch}
          />
          <TextInput
            blur={() => console.log('blur')}
            change={changeHeaderSearch}
            name="address"
            value={searchHeaderColumns.address.searchText}
            disabled={searchHeaderColumns.address.disableSearch}
            placeholder="Search address"
          />
        </div>
      </th>
    </tr>
  )
}

PracticeContactsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = (store, props) => {
  const loggedInUser = store.user.loggedIn.user
  const userMap = store.user.byId
  const userStore = store.user
  const loggedInStaff = store.staff.loggedInByFirm[props.match.params.firmId]
    ? store.staff.loggedInByFirm[props.match.params.firmId].staff
    : null
  let userListArgsObj

  if (loggedInUser && loggedInUser.admin) {
    userListArgsObj = { _firm: props.match.params.firmId }
  } else if (loggedInStaff) {
    userListArgsObj = { _staff: loggedInStaff._id }
  }

  const userListArgs = userListArgsObj
    ? routeUtils.listArgsFromObject(userListArgsObj)
    : null
  const userListItems = userListArgs
    ? store.user.util.getList(...userListArgs)
    : null
  const userList = userListItems
    ? userListArgs.reduce((obj, nextKey) => obj[nextKey], store.user.lists)
    : null

  const filter = userList ? userList.filter : null
  const sortBy = filter ? filter.sortBy : 'contact'

  let listItems = []
  let paginatedList = []
  let filteredByQuery = []
  let orderedList = []

  console.log('userListItems', userListItems)

  if (userList && userListItems && userListItems.length) {
    // const filter = userList.filter;
    const pagination = userList.pagination || { page: 1, per: 50 }
    const query = userList.query || ''

    userListItems.forEach(item => {
      const data = _.cloneDeep(item)
      let showMe = true

      // praparing data ----------------------------------------------------------------------

      data.contactFullName = ''
      data.address = ''
      data.contactFullName = data.firstname || ''
      data.contactFullName += (data.firstname ? ' ' : '') + data.lastname || ''
      data.contactEmail = data.username
      data.phoneNumber =
        data.phonenumber &&
        data.phonenumber.number &&
        displayUtils.formatPhoneNumber(data.phonenumber.number, 'National')
          ? displayUtils.formatPhoneNumber(data.phonenumber.number, 'National')
          : null
      if (data.phonenumber && data.phonenumber.extNumber) {
        data.phoneNumber += ' ' + data.phonenumber.extNumber
      }
      if (data.objaddress) {
        const address = data.objaddress
        data.address = address.street1 || ''
        data.address += (data.address ? ' ' : '') + address.city || ''
        data.address +=
          (data.address ? ' ' : '') + ` ${address.state ? address.state : ''}`
        data.address += (data.address ? ' ' : '') + address.country || ''
      }

      // filtering data --------------------------------------------------------------------------------
      if (data && data.username.match(/hideme.ricblyz/g)) showMe = false

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.contactFullName &&
        userStore.filterHeaders.contactFullName.trim()
      ) {
        const contactFullName =
          userStore.filterHeaders.contactFullName.toLowerCase()
        showMe =
          data.contactFullName &&
          data.contactFullName.toLowerCase().indexOf(contactFullName) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.contactEmail &&
        userStore.filterHeaders.contactEmail.trim()
      ) {
        const contactEmail = userStore.filterHeaders.contactEmail.toLowerCase()
        showMe =
          data.contactEmail &&
          data.contactEmail.toLowerCase().indexOf(contactEmail) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.address &&
        userStore.filterHeaders.address.trim()
      ) {
        const address = userStore.filterHeaders.address.toLowerCase()
        showMe =
          data.address && data.address.toLowerCase().indexOf(address) > -1
      }

      if (
        showMe &&
        !!userStore.filterHeaders &&
        !!userStore.filterHeaders.phoneNumber &&
        userStore.filterHeaders.phoneNumber.trim()
      ) {
        const phoneNumber = userStore.filterHeaders.phoneNumber
        showMe = data.phoneNumber && data.phoneNumber.indexOf(phoneNumber) > -1
      }

      if (showMe) listItems.push(data)
    })

    if (listItems) {
      // SORT THE LIST
      switch (sortBy) {
        case 'contact':
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['asc']
          )
          break
        case '-contact':
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['desc']
          )
          break
        case 'email':
          orderedList = _.orderBy(
            listItems,
            [item => item.username.toLowerCase()],
            ['asc']
          )
          break
        case '-email':
          orderedList = _.orderBy(
            listItems,
            [item => item.username.toLowerCase()],
            ['desc']
          )
          break
        default:
          orderedList = _.orderBy(
            listItems,
            [item => item.firstname.toLowerCase()],
            ['asc']
          )
      }
    }

    // APPLY PAGINATION
    const start = (pagination.page - 1) * pagination.per
    const end = start + pagination.per
    paginatedList = _.slice(orderedList, start, end)
  }
  return {
    userList,
    paginatedList,
    orderedList,
    phoneNumberStore: store.phoneNumber,
    addressStore: store.address,
    loggedInUser,
    staffStore: store.staff,
    contacts: listItems,
    userMap: store.user.byId,
    userStore,
  }
}

export default withRouter(connect(mapStoreToProps)(PracticeContactsList))
