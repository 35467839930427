import React, { ReactDOMServer, useEffect, ReactDOM } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { CopyBlock, dracula } from 'react-code-blocks'

import GridSample2 from './patterns/grid.jsx'
// import firm components
import PracticeLayout from '../../global/practice/components/PracticeLayout.js.jsx'
import PracticeUISwitcher from '../../global/practice/components/PracticeUISwitcher.js.jsx'

const StyleGuide = () => {
  const guides = []
  // Intro
  guides.push({
    id: 'guide-intro',
    title: 'Introduction',
    description: (
      <div className="">
        <p className="mb-5">
          The purpose of this guide is to empower the development team with the
          ability to rapidly build screens that will work and flow to both UI
          versions. The new UI project used little to no DOM manipulation and
          relied soley on CSS to deliver the look and feel. The idea is now to
          allow for UI development that doesn't have to lean heavily on style
          sheets, but uses utility and helper classes to accomplish the heavy
          lifting.
        </p>
        <p className="mb-5">
          Moving forward as new features are developed, there needed to be a way
          to create a screen layout or ui change without having to consider both
          interfaces (including mobile). This is why this layout framework was
          created. This guide will explain the principles on how to use the
          framework to create layout easily.
        </p>
        <p>
          All the styles from the Layout Guide are located in the file:{' '}
          <code>theme/lexShare/layout-styles.scss</code>
        </p>
      </div>
    ),
    jsx: null,
    string: null,
  })
  // Mobile First
  guides.push({
    id: 'responsive-layout',
    title: 'Mobile First Approach',
    description: (
      <div className="">
        <p className="mb-5">
          The Layout Framework that is described in this guide takes a "mobile
          first" approach. Basically what that means is that the smallest screen
          widths are targeted by default and those styles cascade upwards to all
          larger screen widths. Breakpoints exist for tablet, and small, medium,
          and large screens widths all of which are larger then the mobile
          default. Any styles applied to these breakpoints will also cascade
          upwards to all larger screens. So for example if a rule is applied at
          the small breakpoint, medium, and large would also have it applied. If
          a rule was then applied to the large breakpoint, small and medium
          would still have the same styles, but anything specified at the large
          breakpoint would override the styles from small.
        </p>
        <p className="mb-5">
          Targeting and applying layout rules at specific breakpoints can be
          done through applying helper classes to DOM elements. Many of the
          helper classes can be modified with a breakpoint prefix shown in the
          table below.
        </p>
        <h3 className="mb-4">Breakpoint class prefixes and dimensions</h3>
        <div className="sm:grid-12 clip-height">
          <table className="sm:col-6 w-full guide-table">
            <tr>
              <th>Class Prefix</th>
              <th>Dimension</th>
            </tr>
            <tr>
              <td>tablet</td>
              <td>481px</td>
            </tr>
            <tr>
              <td>sm</td>
              <td>1025px</td>
            </tr>
            <tr>
              <td>md</td>
              <td>1201px</td>
            </tr>
            <tr>
              <td>lg</td>
              <td>1791px</td>
            </tr>
          </table>
        </div>
      </div>
    ),
    jsx: null,
    string: null,
  })
  // Grid System
  guides.push({
    id: 'grid-layout',
    string: `<div className="sm:grid-12 bg-panel row-gap-4 mb-4">
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm"><span>.col-1</span></div>
    <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm"><span>.col-3</span></div>
    <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm"><span>.col-3</span></div>
    <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm"><span>.col-3</span></div>
    <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm"><span>.col-3</span></div>
</div>`,
    jsx: (
      <div className="sm:grid-12 bg-panel row-gap-4 mb-4">
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-1 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-1</span>
        </div>
        <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-3 .lg:col-6</span>
        </div>
        <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-3 .lg:col-6</span>
        </div>
        <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-3 .lg:col-6</span>
        </div>
        <div className="sm:col-3 lg:col-6 bg-stage p-2 h-8 rounded-sm">
          <span>.sm:col-3 .lg:col-6</span>
        </div>
      </div>
    ),
    title: 'Responsive Grid System',
    description: (
      <div>
        <p className="mb-5">
          This responsive grid system provides a very simple framework for
          laying out screens. It will also render correctly in both UI versions
          so if new pages can be created using this system (and/or the Flexbox
          Layout), additional changes and considerations are not needed to
          service both UIs.
        </p>
        <p className="mb-5">
          The grid is built with a "mobile-first" approach meaning that styles
          target the smallest screen with and flow upwards from there. All grids
          on mobile will automatically become single column. Targeting the next
          highest breakpoint (which is "sm") will allow for those defined styles
          to override everything from sm all the way up to the largest
          breakpoint. Laying out screens with this grid means that the UI will
          work on desktop and mobile, and on the old UI and the new.
        </p>
        <p className="mb-5">
          The class prefixes allow you to target a specific breakpoint (and
          above) so you can layout and styling rules to targeted screens and
          devices.
        </p>
        <p className="mb-5">
          In the grid below there is a 12 column grid created with{' '}
          <code>sm:grid-12</code>, this means everything at the sm breakpoint
          (1025px) and up will have these styles applied. Typically most grids
          will follow this convention and use only the sm breakpoint, unless
          there are tweaks that you may want to make on larger screens. In the
          example below the last four columns have an additional class of{' '}
          <code>lg:col-6</code>. This means any screen at the lg breakpoint
          (1791px) and up will have the associated styles applied.
        </p>
      </div>
    ),
  })
  // Flexbox
  guides.push({
    id: 'flexbox-layout',
    title: 'Flexbox Layout',
    description: (
      <div className="">
        <p className="mb-5">
          The responsive grid system in the project is a very versatile tool for
          creating page layouts, but in most cases Flexbox is the best option.
          Flexbox is great for distributing content across a single row. With
          the designated helper classes, you can leverage flexbox on both UIs
          and create fluid layouts with less code.
        </p>
        <p className="mb-5">
          The <code>.gap-</code> class can be used for spacing items that have
          been aligned using flexbox. Numbers 1-10 can be used following the
          dash for various dimension sizes.
        </p>
        <div className="sm:grid-8 mb-6">
          <div className="sm:col-4 lg:col-4">
            <h3 className="mb-4">Flexbox Classes</h3>
            <div className="clip-height">
              <table className="guide-table w-full">
                <tr>
                  <th>Class</th>
                  <th>Properties</th>
                </tr>
                <tr>
                  <td>.flex-row</td>
                  <td>
                    display: flex;
                    <br />
                    flex-direction: row;
                  </td>
                </tr>
                <tr>
                  <td>.flex-col</td>
                  <td>
                    display: flex;
                    <br />
                    flex-direction: column;
                  </td>
                </tr>
                <tr>
                  <td>.justify-start</td>
                  <td>justify-content: flex-start;</td>
                </tr>
                <tr>
                  <td>.justify-between</td>
                  <td>justify-content: space-between;</td>
                </tr>
                <tr>
                  <td>.justify-around</td>
                  <td>justify-content: space-around;</td>
                </tr>
                <tr>
                  <td>.justify-end</td>
                  <td>justify-content: flex-end;</td>
                </tr>
                <tr>
                  <td>.justify-center</td>
                  <td>justify-content: center;</td>
                </tr>
                <tr>
                  <td>.items-start</td>
                  <td>align-items: flex-start;</td>
                </tr>
                <tr>
                  <td>.items-center</td>
                  <td>align-items: center;</td>
                </tr>
                <tr>
                  <td>.items-end</td>
                  <td>align-items: flex-end;</td>
                </tr>
                <tr>
                  <td>.gap-2</td>
                  <td>gap: .5rem;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <p className="mb-5">
          By using the <code>.items-center</code> class to align all content
          across a horizontal axis, in combination with using the CSS gap
          property for the spacing so margins are not required, we can create
          dynamic layouts that space and align automatically. For the content on
          the left, <code>.justify-start</code> is used to pull all content
          left. For the content on the right <code>.justify-end</code> is used
          to pull all content to the right. These spacing and alignment
          practices are perfect for toolbars or control rows for grids and
          tables.
        </p>
      </div>
    ),
    jsx: (
      <div className="rounded-md bg-stage flex-row justify-between p-2 gap-2 items-center">
        <div className="flex-row gap-2 justify-start">
          <div className="bg-card rounded-sm px-4 py-2">Left 1</div>
          <div className="bg-card rounded-sm px-4 py-2">Left 2</div>
          <div className="bg-card rounded-sm px-4 py-2">Left 3</div>
        </div>
        <div className="flex-row gap-2 justify-end">
          <div className="bg-card rounded-sm px-4 py-2">Right 1</div>
          <div className="bg-card rounded-sm px-4 py-2">Right 2</div>
          <div className="bg-card rounded-sm px-4 py-2">Right 3</div>
        </div>
      </div>
    ),
    string: `<div className='flex-row justify-between gap-2 items-center'>
    <div className='flex-row gap-2 justify-start'>
        <div>Left 1</div>
        <div>Left 2</div>
        <div>Left 3</div>
    </div>
    <div className='flex-row gap-2 justify-end'>
        <div>Right 1</div>
        <div>Right 2</div>
        <div>Right 3</div>
    </div>
</div>`,
  })
  // CSS Helpers
  guides.push({
    id: 'css-helpers',
    title: 'CSS Class Helpers',
    description: (
      <div className="">
        <p className="mb-5">
          There are numerous CSS helper classes that can be used to make
          building UIs faster. Some of these, not all, can be modified with the
          breakpoint prefixes to only apply the resulting styles to certain
          screens or devices.
        </p>
        <p className="mb-5">
          The following properties can be modified with the breakpoint prefixes
          like <code>sm:m-0</code>. The number following the hyphen can be
          incremented (1-10) to increase the dimensional value as well. This
          syntax would look like <code>sm:m-6</code>.
        </p>
        <h3 className="mb-4">UI Targeting</h3>
        <p className="mb-5">
          If you need to target one specific UI with the helper class you can
          use a prefix. LexShare is <code>ls:</code> and MangoShare is{' '}
          <code>is:</code>. Here's how you would use these:{' '}
          <code>ls:sm:mb-4</code>. This would put a margin bottom of 1rem only
          in LexShare. The same for MangoShare would be: <code>is:sm:mb-4</code>
          .
        </p>
        <div className="sm:grid-12 sm:col-gap-6 ">
          <div className="sm:col-6 clip-height mb-5">
            <h3 className="mb-3">Margin</h3>
            <table className="w-full guide-table ">
              <tr>
                <th>Class</th>
                <th>Properties</th>
              </tr>
              <tr>
                <td>m-0</td>
                <td>margin: 0px;</td>
              </tr>
              <tr>
                <td>mx-0</td>
                <td>
                  margin-left: 0px;
                  <br />
                  margin-right: 0px;
                </td>
              </tr>
              <tr>
                <td>my-0</td>
                <td>
                  margin-top: 0px;
                  <br />
                  margin-bottom: 0px;
                </td>
              </tr>
              <tr>
                <td>ml-0</td>
                <td>margin-left: 0px;</td>
              </tr>
              <tr>
                <td>mr-0</td>
                <td>margin-right: 0px;</td>
              </tr>
              <tr>
                <td>mt-0</td>
                <td>margin-top: 0px;</td>
              </tr>
              <tr>
                <td>mb-0</td>
                <td>margin-bottom: 0px;</td>
              </tr>
            </table>
          </div>
          <div className="sm:col-6 clip-height mb-5">
            <h3 className="mb-3">Padding</h3>
            <table className="w-full guide-table ">
              <tr>
                <th>Class</th>
                <th>Properties</th>
              </tr>
              <tr>
                <td>p-0</td>
                <td>padding: 0px;</td>
              </tr>
              <tr>
                <td>px-0</td>
                <td>
                  padding-left: 0px;
                  <br />
                  padding-right: 0px;
                </td>
              </tr>
              <tr>
                <td>py-0</td>
                <td>
                  padding-top: 0px;
                  <br />
                  padding-bottom: 0px;
                </td>
              </tr>
              <tr>
                <td>pl-0</td>
                <td>padding-left: 0px;</td>
              </tr>
              <tr>
                <td>pr-0</td>
                <td>padding-right: 0px;</td>
              </tr>
              <tr>
                <td>pt-0</td>
                <td>padding-top: 0px;</td>
              </tr>
              <tr>
                <td>pb-0</td>
                <td>padding-bottom: 0px;</td>
              </tr>
            </table>
          </div>
          <div className="sm:col-6 clip-height mb-5">
            <h3 className="mb-3">Width</h3>
            <table className="w-full guide-table ">
              <tr>
                <th>Class</th>
                <th>Properties</th>
              </tr>
              <tr>
                <td>w-full</td>
                <td>width: 100%;</td>
              </tr>
              <tr>
                <td>w-0</td>
                <td>width: 0px;</td>
              </tr>
              <tr>
                <td>w-1</td>
                <td>width: .25rem;</td>
              </tr>
              <tr>
                <td>w-2</td>
                <td>width: .5rem;</td>
              </tr>
              <tr>
                <td>w-3</td>
                <td>width: .75rem;</td>
              </tr>
              <tr>
                <td>w-4</td>
                <td>width: 1rem;</td>
              </tr>
              <tr>
                <td>w-5</td>
                <td>width: 2rem;</td>
              </tr>
            </table>
          </div>
          <div className="sm:col-6 clip-height mb-5">
            <h3 className="mb-3">Height</h3>
            <table className="w-full guide-table ">
              <tr>
                <th>Class</th>
                <th>Properties</th>
              </tr>
              <tr>
                <td>h-full</td>
                <td>height: 100%;</td>
              </tr>
              <tr>
                <td>h-0</td>
                <td>height: 0px;</td>
              </tr>
              <tr>
                <td>h-1</td>
                <td>height: .25rem;</td>
              </tr>
              <tr>
                <td>h-2</td>
                <td>height: .5rem;</td>
              </tr>
              <tr>
                <td>h-3</td>
                <td>height: .75rem;</td>
              </tr>
              <tr>
                <td>h-4</td>
                <td>height: 1rem;</td>
              </tr>
              <tr>
                <td>h-5</td>
                <td>height: 2rem;</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    string: null,
    jsx: null,
  })
  // Buttons
  guides.push({
    id: 'ui-buttons',
    title: 'UI: Buttons',
    string: `<button class="yt-btn x-small info">Simple Button</button>
<button class="yt-btn x-small link">Simple Button</button>
<button class="yt-btn small info">Simple Button</button>
<button class="yt-btn small link">Simple Button</button>`,
    jsx: (
      <div className="flex-row justify-start gap-4 items-center">
        <button className="yt-btn x-small info">Simple Button</button>
        <button className="yt-btn x-small link">Simple Button</button>
        <button className="yt-btn small info">Simple Button</button>
        <button className="yt-btn small link">Simple Button</button>
      </div>
    ),
    description: (
      <div className="mb-5">
        <p className="mb-5">
          Below are examples of button controls in the application.
        </p>
      </div>
    ),
  })
  // Text Inputs
  guides.push({
    id: 'ui-inputs',
    title: 'UI: Text Inputs',
    string: `<div class="search-input">
    <div class="input-add-on">
        <i class="item fal fa-search" aria-hidden="true"></i>
        <input autocomplete="off" type="search" name="query" placeholder="Search..." class="" value="" />
    </div>
</div>

<div class="input-group">
    <label for=""></label>
    <input class="" name="email" placeholder="Enter email..." required="" type="email" value=""/>
    <small class="help-text"><em>This is the help text</em></small>
</div>`,
    jsx: (
      <div className="">
        <div className="flex-col justify-start gap-4 items-start">
          <div className="search-input">
            <div className="input-add-on">
              <i
                className="item fal fa-search"
                aria-hidden="true"
              ></i>
              <input
                autoComplete="off"
                type="search"
                name="query"
                placeholder="Search..."
                className=""
                value=""
              />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor=""></label>
            <input
              className=""
              name="email"
              placeholder="Enter email..."
              required=""
              type="email"
              value=""
            />
            <small className="help-text">
              <em>This is the help text</em>
            </small>
          </div>
        </div>
      </div>
    ),
    description: (
      <div className="mb-5">
        <p className="mb-5">
          Below are examples of the text inputs in the application.
        </p>
      </div>
    ),
  })

  return (
    <PracticeLayout>
      <PracticeUISwitcher />
      <Helmet>
        <title>Layout Guide</title>
      </Helmet>
      <div className="yt-container fluid style-guide">
        <h1 id="guide-top">Layout Guide</h1>
        <div className="stage bg-card sm:grid-12 rounded-md overflow-hidden">
          <div className="sm:col-2 pt-6 p-5 border-r bg-menu">
            <h3 className="mb-5">Glossary</h3>
            <ul>
              {guides.map(function (g, i) {
                return (
                  <li
                    key={i}
                    className="mb-3"
                  >
                    <a href={'#' + g.id}>{g.title}</a>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="sm:col-10 pt-6 p-5">
            {guides.map(function (g, i) {
              return (
                <div
                  className="guide-block"
                  key={i}
                  id={g.id}
                >
                  {g.title ? <h2 className="mb-5">{g.title}</h2> : null}
                  {g.description ? g.description : null}
                  {g.jsx ? (
                    <div className="mt-6">
                      <h3 className="mb-4">Example</h3>
                      <div className="mb-5">{g.jsx}</div>
                    </div>
                  ) : null}
                  {g.string ? (
                    <div className="rounded-sm clip-height">
                      <CopyBlock
                        language={'html'}
                        text={g.string}
                        showLineNumbers={true}
                        theme={dracula}
                        wrapLines={true}
                        codeBlock
                      />
                    </div>
                  ) : null}

                  <p className="my-4">
                    <a href="#guide-top">Back to top</a>
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </PracticeLayout>
  )
}
export default StyleGuide
