/**
 * View component for /share-links/:shareLinkId
 *
 * Displays a single shareLink from the 'byId' map in the shareLink reducer
 * as defined by the 'selected' property
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, history, withRouter } from 'react-router-dom'

// import actions
import * as clientActions from '../../client/clientActions'
import * as firmActions from '../../firm/firmActions'
import * as quickTaskActions from '../../quickTask/quickTaskActions'
import * as shareLinkActions from '../shareLinkActions'

// import global components
import AlertModal from '../../../global/components/modals/AlertModal.js.jsx'
import Binder from '../../../global/components/Binder.js.jsx'
import DefaultTopNav from '../../../global/components/navigation/DefaultTopNav.js.jsx'
import ProfilePic from '../../../global/components/navigation/ProfilePic.js.jsx'
import ProfileDropdown from '../../../global/components/navigation/ProfileDropdown.js.jsx'
import { FileInput, TextInput } from '../../../global/components/forms'
import {
  createFolders,
  notifyUpload,
  createItemUploads,
} from '../../file/fileActions'

// import resource components
import ClientNoteItem from '../../clientNote/components/ClientNoteItem.js.jsx'
import PreviewFile from '../../file/components/PreviewFile.js.jsx'
import ShareLinkAuthForm from '../components/ShareLinkAuthForm.js.jsx'
import ShareLinkLayout from '../components/ShareLinkLayout.js.jsx'
import ShareLinkNav from '../components/ShareLinkNav.js.jsx'
import RecipientInput from '../../quickTask/practice/components/RecipientInput.js.jsx'

import classNames from 'classnames'
import { DateTime } from 'luxon'
import { auth } from '../../../global/utils'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import UISwitchChecker from '../../../global/practice/components/PracticeUISwitcher.js.jsx'

class ViewFileRequest extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      alertModalOpen: false,
      errorMessage: '',
      expired: false,
      files: [],
      password: '',
      uploadItems: {},
      submitting: false,
      submitted: false,
      uploadName: '',
      uploadEmailAddress: '',
      uploadCompanyName: '',
      uploadNameSet: false,
      folders: [],
      selectedFirm: {},
      receivers: [],
    }
    this._bind(
      '_handleAuthenticateLink',
      '_handleDisableLink',
      '_handleFilesChange',
      '_handleFormChange',
      '_handleReload',
      '_handleSubmitFiles',
      '_addRecipient',
      '_removeRecipient'
    )
  }

  componentDidMount() {
    console.log('did mount')
    const { dispatch, loggedInUser, match } = this.props

    dispatch(shareLinkActions.fetchSingleByHex(match.params.hex)).then(
      slRes => {
        console.log('slRes', slRes)
        if (slRes.success) {
          // console.log('retrieved')
          // console.log(slRes);

          if (slRes.item && slRes.item.firm && slRes.item.firm) {
            dispatch(firmActions.fetchSingleIfNeeded(slRes.item.firm._id))
          }

          if (slRes.authenticated) {
            // dispatch file action here
          }

          this.setState({
            selectedFirm: slRes.item.firm,
          })
        } else {
          alert('no link found')
        }
      }
    )
    dispatch(firmActions.fetchSingleFirmByDomain())
  }

  _handleFilesChange(files, folders) {
    console.log('-------- files -----')
    console.log(files)
    console.log(folders)
    if (folders && folders.length) {
      this.setState({ files, folders })
    } else {
      this.setState({ files })
    }
  }

  async _handleSubmitFiles(e) {
    const { close, dispatch, match, shareLinkStore, handleUploaded } =
      this.props
    if (e) {
      e.preventDefault()
    }

    // convert to a FormData objet to allow uploading file=

    let { files, folders, receivers } = this.state

    files = files.filter(file => !file.virusDetected && !file.fileNotFound)
    if (!files.length && !folders.length) {
      alert('No files present')
    } else {
      // save new files list;
      this.setState({ files })
      const params = shareLinkStore.selectedHex.getItem()

      params.uploadName = this.state.uploadName
      params.uploadEmailAddress = this.state.uploadEmailAddress
      params.uploadCompanyName = this.state.uploadCompanyName
      params.receivers = receivers.map(({ emailError, ...r }) => r)

      this.setState({ submitting: true })

      try {
        const createdFolders = await dispatch(
          createFolders({ folders, params })
        )
        const uploads = dispatch(
          createItemUploads({
            files,
            folders: createdFolders,
            params,
          })
        ).map(async upload => {
          for await (const { type, value } of upload) {
            if (type === `progress`) {
              const {
                root: { _id, name },
                percent,
              } = value
              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    percent,
                  },
                },
              }))
            }

            if (type === `failedMessages`) {
              const {
                root: { _id, name },
                failedMessages,
              } = value

              this.setState(prevState => ({
                uploadItems: {
                  ...prevState.uploadItems,
                  [_id]: {
                    ...prevState.uploadItems[_id],
                    name,
                    failedMessages,
                  },
                },
              }))
            }

            if (type === `done`) {
              return value
            }
          }
        })

        const results = await Promise.allSettled(uploads)
        const { roots, createdFiles, failedMessages } = results
          .map(({ value }) => value)
          .reduce(
            (acc, { root, createdFiles, failedMessages }) => {
              acc.roots = [...acc.roots, root]
              acc.createdFiles = [...acc.createdFiles, ...createdFiles]
              acc.failedMessages = [...acc.failedMessages, ...failedMessages]
              return acc
            },
            {
              roots: [],
              createdFiles: [],
              failedMessages: [],
            }
          )

        if (createdFiles.length) {
          await Promise.all([
            // TODO check viewingAs
            dispatch(notifyUpload({ files: createdFiles, params })),
            dispatch(
              shareLinkActions.updateWithFiles({
                hex: match.params.hex,
                files: createdFiles,
                receivers: receivers.map(({ email }) => email),
              })
            ),
          ])

          if (handleUploaded) {
            handleUploaded(roots)
          }
        }
      } catch (e) {
        alert(e.message)
      }

      this.setState({
        submitting: false,
        submitted: true,
      })
    }
  }

  _handleAuthenticateLink(e) {
    if (e) {
      e.preventDefault()
    }
    console.log('authenticate link')
    const { dispatch, match, shareLinkStore } = this.props
    const selectedShareLink = _.cloneDeep(shareLinkStore.selectedHex.getItem())
    // If it is 'shared-client-secret' then password needs to be hashed.
    const password =
      selectedShareLink &&
      (selectedShareLink.authType === 'shared-client-secret' ||
        selectedShareLink.authType === 'shared-contact-secret')
        ? auth.getHashFromString(_.snakeCase(this.state.password))
        : this.state.password
    dispatch(
      shareLinkActions.sendAuthenticateLink(match.params.hex, {
        password: password,
      })
    ).then(slRes => {
      if (slRes.success) {
        // do nothing. deternmination of link's authentication status is handled on the reducer
        this.setState({
          selectedFirm: slRes.item.firm,
        })
      } else {
        this.setState({
          alertModalOpen: true,
        })
      }
    })
  }

  _handleDisableLink() {
    const { dispatch, history, match, shareLinkStore } = this.props
    const selectedShareLink = _.cloneDeep(shareLinkStore.selectedHex.getItem())
    const updatedShareLink = {
      _id: selectedShareLink._id,
      expireDate: new Date(),
    }
    dispatch(
      shareLinkActions.sendUpdateShareLinkWithPermission(updatedShareLink)
    ).then(slRes => {
      console.log('done')
      if (slRes.success) {
        // force refresh
        window.location.reload()
      } else {
        alert('Something went wrong')
      }
    })
  }

  _handleFormChange(e, action) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let name
    let value

    if (e === 'receiver') {
      name = action.target.name.replace('recipients', 'receivers')
      value = action.target.value
    } else {
      name = e.target.name
      value = e.target.value
    }

    let newState = _.update(_.cloneDeep(this.state), name, () => {
      return value
    })
    this.setState(newState)
  }

  _handleReload() {
    const { dispatch, match } = this.props
    dispatch(shareLinkActions.fetchSingleByHex(match.params.hex))
    this.setState({
      alertModalOpen: false,
      files: [],
      submitted: false,
      uploadItems: {},
      submitting: false,
    })
  }

  _addRecipient(action) {
    if (action === 'receiver') {
      let receivers = _.cloneDeep(this.state.receivers)
      const emailLists = {
        email: '',
      }
      receivers.push(emailLists)
      this.setState({ receivers })
    } else {
      let recipients = _.cloneDeep(this.state.recipients)
      const emailLists = {
        email: '',
      }
      recipients.push(emailLists)
      this.setState({ recipients })
    }
  }

  _removeRecipient(index, action) {
    /**
     * NOTE: The user can add as many recipients to the recipients array as they want.
     * If they want to remove a recipient, we'll have to remove it from the array while
     * preserving the index of the remaining recipients. Normally we wouldn't have to preserve
     * the original index, but because we must preserve the type of recipient (existing or new)
     * changing the index on the array causes it to unmount, rerender, and lose its local state.
     * This is why we delete it rather than filter it out. This will leave undefined entries in the
     * array, but will preserve the index of all entries. We'll filter out the undefined entries right
     * before we create the shareLink (above, on the _handleCreateShareLink method).
     *
     * There must be a way cleaner way to do this, but this works. -Wes
     */
    if (action && action === 'receiver') {
      let newReceivers = _.cloneDeep(this.state.receivers)
      delete newReceivers[index]
      this.setState({
        receivers: newReceivers.filter(removeNull => removeNull),
      })
    } else {
      let newRecipients = _.cloneDeep(this.state.recipients)
      delete newRecipients[index]
      this.setState({
        recipients: newRecipients.filter(removeNull => removeNull),
      })
    }
  }

  render() {
    const {
      clientStore,
      fileStore,
      firmStore,
      location,
      loggedInUser,
      shareLinkStore,
    } = this.props

    const {
      files,
      submitting,
      submitted,
      selectedFirm,
      receivers,
      uploadItems,
    } = this.state

    /**
     * use the selected.getItem() utility to pull the actual shareLink object from the map
     */
    const selectedShareLink = shareLinkStore.selectedHex.getItem()
    // console.log(selectedShareLink)
    // console.log(shareLinkStore.selectedHex);
    // console.log(shareLinkStore.byHex[match.params.hex])

    // const userFirms = loggedInUser && loggedInUser._id ? firmStore.util.getList(userFirmListArgs);
    const userFirmList =
      loggedInUser &&
      loggedInUser._id &&
      firmStore.lists &&
      firmStore.lists._user
        ? firmStore.lists._user[loggedInUser._id]
        : null

    const isEmpty =
      !selectedShareLink ||
      !selectedShareLink._id ||
      shareLinkStore.selectedHex.didInvalidate

    const isFetching = shareLinkStore.selectedHex.isFetching

    const isExpired =
      !isEmpty &&
      selectedShareLink.expireDate &&
      DateTime.fromISO(selectedShareLink.expireDate) < DateTime.local()

    const isClosed =
      !isEmpty &&
      selectedShareLink &&
      selectedShareLink.quickTask &&
      selectedShareLink.quickTask.visibility === 'archived'

    const isAuthenticated = shareLinkStore.selectedHex.isAuthenticated

    const previewClass = classNames('file-preview-container', {
      '-with-sidebar': this.state.showSideBar,
    })

    const sideBarClass = classNames('file-preview-sidebar', {
      '-hidden': !this.state.showSideBar,
    })

    const sideMenuClass = classNames('-sidebar-menu', {
      '-open': this.state.showSideBar,
    })

    const btnFileValid = !files.some(f => !f.virusDetected && !f.fileNotFound)

    const availableStaff =
      selectedShareLink &&
      selectedShareLink.firm &&
      selectedShareLink.firm.allowAddRecipientFileRequest &&
      selectedShareLink.firmStaff &&
      selectedShareLink.firmStaff.length
        ? selectedShareLink.firmStaff.map(user => {
            let fullName = user.firstname + ' ' + user.lastname
            user.displayName = `${fullName} | ${user.username}`
            user.email = user.username
            return user
          })
        : []
    const email = receivers[0] && receivers[0].email

    const failedMessages = Object.values(uploadItems)
      .filter(({ failedMessages }) => failedMessages)
      .flatMap(({ failedMessages }) => failedMessages)

    return (
      <div>
        <UISwitchChecker />
        <Helmet>
          <title>File Request</title>
        </Helmet>
        {isEmpty ? (
          isFetching ? (
            <div className="-loading-hero hero">
              <div className="u-centerText">
                <div className="loading"></div>
              </div>
            </div>
          ) : (
            <div className="flex column">
              <section className="section white-bg the-404">
                <div className="hero flex three-quarter">
                  <div className="yt-container slim">
                    <h1>
                      Whoops!{' '}
                      <span className="light-weight">Something wrong here</span>
                    </h1>
                    <hr />
                    <h4>
                      Either this link no longer exists, or your credentials are
                      invalid.
                    </h4>
                  </div>
                </div>
              </section>
            </div>
          )
        ) : (
          <div style={{ opacity: isFetching ? 0.5 : 1 }}>
            {isExpired ? (
              <div className="flex column">
                <section className="section white-bg the-404">
                  <div className="hero flex three-quarter">
                    <div className="yt-container slim">
                      <h1>
                        Whoops!{' '}
                        <span className="light-weight">
                          This link is expired
                        </span>
                      </h1>
                      <hr />
                      <h4>
                        You can <Link to="/user/login">sign in</Link> to view
                        your account, or request a new link.
                      </h4>
                    </div>
                  </div>
                </section>
              </div>
            ) : isClosed ? (
              <div className="share-link-layout">
                <ShareLinkNav />
                <div className="body with-header">
                  <div className="yt-container slim">
                    <div>
                      <h1>Oops!</h1>
                      <hr />
                      <p>This task is no longer available.</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : selectedShareLink.authType !== 'none' && !isAuthenticated ? (
              <div className="share-link-layout">
                <DefaultTopNav />
                <div
                  className="yt-row center-horiz"
                  style={{ marginTop: '128px' }}
                >
                  <ShareLinkAuthForm
                    handleFormChange={this._handleFormChange}
                    handleFormSubmit={this._handleAuthenticateLink}
                    password={this.state.password}
                    prompt={selectedShareLink.prompt}
                    shareLink={selectedShareLink}
                  />
                </div>
              </div>
            ) : (
              <div className="share-link-layout">
                <ShareLinkNav />
                <div className="body with-header">
                  <div className="yt-container slim">
                    {userFirmList &&
                    userFirmList.items.includes(selectedShareLink._firm) ? (
                      <button
                        className="yt-btn danger x-small u-pullRight"
                        onClick={this._handleDisableLink}
                      >
                        Disable this link
                      </button>
                    ) : null}
                    <h3>
                      {selectedShareLink.firm ? (
                        <span> {selectedShareLink.firm.name} </span>
                      ) : (
                        <span> Your accountant </span>
                      )}
                      is requesting files
                    </h3>
                    {selectedShareLink.client ? (
                      <p className="u-muted">
                        for {selectedShareLink.client.name}
                      </p>
                    ) : null}
                    {selectedShareLink.expireDate ? (
                      <p>
                        <span
                          style={{ fontStyle: 'italic' }}
                          className="u-muted"
                        >
                          Link expires on{' '}
                        </span>
                        <span style={{ fontWeight: '500' }}>
                          {moment(selectedShareLink.expireDate)
                            .utc()
                            .format('MM/DD/YYYY')}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    <hr />
                    {!loggedInUser._id && !this.state.uploadNameSet ? (
                      <div className="yt-col full m_50 l_40 xl_33">
                        <TextInput
                          autoFocus
                          name="uploadName"
                          placeholder="Enter your name"
                          change={this._handleFormChange}
                          value={this.state.uploadName}
                        />
                        {selectedFirm && selectedFirm.showEmail ? (
                          <TextInput
                            name="uploadEmailAddress"
                            placeholder="Enter your email address"
                            change={this._handleFormChange}
                            value={this.state.uploadEmailAddress}
                          />
                        ) : null}
                        {selectedFirm &&
                        selectedFirm.showCompany &&
                        !selectedShareLink._client ? (
                          <TextInput
                            name="uploadCompanyName"
                            placeholder="Enter your company name"
                            change={this._handleFormChange}
                            value={this.state.uploadCompanyName}
                          />
                        ) : null}

                        <div className="input-group">
                          <button
                            className="yt-btn x-small info u-pullRight"
                            disabled={
                              !this.state.uploadName ||
                              (selectedFirm.showEmail &&
                                !this.state.uploadEmailAddress) ||
                              (!selectedShareLink._client &&
                                selectedFirm.showCompany &&
                                !this.state.uploadCompanyName)
                            }
                            onClick={() =>
                              this.setState({ uploadNameSet: true })
                            }
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="yt-col full m_50 l_40 xl_33">
                        {selectedShareLink &&
                        selectedShareLink.firm &&
                        selectedShareLink.firm.allowAddRecipientFileRequest ? (
                          <div>
                            <div className="yt-row space-between -view-share-and-request-recepient">
                              <div
                                className="yt-col"
                                style={{ paddingLeft: '5px' }}
                              >
                                <div className="yt-row">
                                  {selectedFirm.allowRequiredRecipient ? (
                                    <p style={{ color: 'red' }}>
                                      <small>
                                        *Recipient required in order to upload
                                        file.
                                      </small>
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                  <button
                                    className="yt-btn xx-small u-pullRight input-group"
                                    onClick={this._addRecipient.bind(
                                      this,
                                      'receiver'
                                    )}
                                  >
                                    <i className="fal fa-plus" />
                                    {receivers.length === 0
                                      ? 'Add recipient'
                                      : 'Add another recipient'}
                                  </button>
                                </div>
                                {receivers.map((receiver, i) => {
                                  return receiver ? (
                                    <RecipientInput
                                      change={this._handleFormChange.bind(
                                        this,
                                        'receiver'
                                      )}
                                      handleRecipientChange={
                                        this._handleRecipientChange
                                      }
                                      currentIndex={i}
                                      key={'receiver_' + i}
                                      recipientListItems={availableStaff}
                                      recipient={receiver}
                                      removeRecipient={() =>
                                        this._removeRecipient(i, 'receiver')
                                      }
                                      filterable={true}
                                      hiddenBtn={true}
                                    />
                                  ) : null
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {!submitted ? (
                          !submitting ? (
                            <div className="-request-file-input -webapp-sharelink">
                              {selectedShareLink.quickTask ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      selectedShareLink.quickTask.prompt || '',
                                  }}
                                ></div>
                              ) : null}
                              <FileInput
                                change={this._handleFilesChange}
                                multiple={true}
                                required={true}
                                dispatch={this.props.dispatch}
                              />
                              <button
                                className="yt-btn small block info"
                                onClick={this._handleSubmitFiles}
                                disabled={
                                  !files ||
                                  files.length < 1 ||
                                  this.state.receivers.some(
                                    r => r && r.emailError
                                  ) ||
                                  submitting ||
                                  btnFileValid ||
                                  (selectedFirm.allowRequiredRecipient
                                    ? email === undefined || email === ''
                                      ? true
                                      : false
                                    : false)
                                }
                              >
                                {submitting ? 'Submitting...' : 'Submit'}
                              </button>
                            </div>
                          ) : (
                            Object.entries(uploadItems).map(
                              ([id, { name, percent, failedMessages }]) => (
                                <div
                                  key={id}
                                  style={{ padding: '1em' }}
                                >
                                  <p>
                                    <small>
                                      <strong>{name}</strong>
                                    </small>
                                    {failedMessages && failedMessages.length
                                      ? ` - ${failedMessages[0]}`
                                      : ` - ${percent}%`}
                                  </p>
                                  <div className={`progress-bar-${percent}`}>
                                    <div className="-progress">
                                      <div className="-complete"></div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          )
                        ) : (
                          <div className="hero">
                            {!failedMessages.length ? (
                              <div className="u-centerText">
                                <h3>Files submitted successfully.</h3>
                                <button
                                  className="yt-btn small info"
                                  onClick={this._handleReload}
                                >
                                  Upload more files
                                </button>
                              </div>
                            ) : (
                              <div className="u-centerText">
                                <h3>Something went wrong.</h3>
                                {Object.entries(uploadItems).map(
                                  ([id, { name, failedMessages }], idx) => (
                                    <div
                                      key={`${name}_${idx}`}
                                      style={{ textAlign: 'left' }}
                                    >
                                      {failedMessages &&
                                      failedMessages.length ? (
                                        <p className="u-danger">
                                          <small>
                                            <strong>{name}</strong>
                                          </small>
                                          {` - ${failedMessages[0]}`}
                                        </p>
                                      ) : (
                                        <p>
                                          <small>
                                            <strong>{name}</strong>
                                          </small>
                                          {` - Successfully uploaded`}
                                        </p>
                                      )}
                                    </div>
                                  )
                                )}
                                <button
                                  className="yt-btn small warning"
                                  onClick={this._handleReload}
                                >
                                  Try again
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <AlertModal
          alertMessage={
            <div>
              <p>
                You did not enter the correct information to access this link.
              </p>
            </div>
          }
          alertTitle="Invalid credentials"
          closeAction={this._handleReload}
          confirmAction={this._handleReload}
          confirmText="Try again"
          isOpen={this.state.alertModalOpen}
          type="danger"
        />
      </div>
    )
  }
}

ViewFileRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */
  return {
    clientStore: store.client,
    fileStore: store.file,
    firmStore: store.firm,
    loggedInUser: store.user.loggedIn.user,
    shareLinkStore: store.shareLink,
  }
}

export default withRouter(connect(mapStoreToProps)(ViewFileRequest))
