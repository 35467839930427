/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as engagementActions from '../engagementActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import TextInput from '../../../global/components/forms/TextInput.js.jsx'

class EngagementForm extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      submitting: false,
      errorMessage: '',
    }
    this._bind('_handleFormChange', '_handleCreate', '_handleClose')
  }

  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleCreate() {
    const { dispatch, match } = this.props
    const name = _cloneDeep(this.state.name)
    if (!(name && name.trim())) return

    this.setState({ submitting: true })
    dispatch(
      engagementActions.sendCreateEngagement({
        name,
        _firm: match.params.firmId,
      })
    ).then(json => {
      if (json.success) {
        this._handleClose()
        this.props.handleCreate(json.item)
      } else {
        alert(json.error)
        this.setState({ submitting: false })
      }
    })
  }

  _handleClose() {
    this.setState(
      {
        name: '',
        submitting: false,
      },
      () => {
        this.props.handleClose()
      }
    )
  }

  render() {
    const { isOpen } = this.props

    const { submitting, name } = this.state

    return (
      <Modal
        cardSize="large"
        isOpen={isOpen}
        closeAction={this._handleClose}
        closeText="Cancel"
        confirmAction={this._handleCreate}
        confirmText={submitting ? 'Saving' : 'Save'}
        disableConfirm={submitting || !(name && name.trim)}
        modalHeader="Create Engagement Type"
        showButtons={true}
      >
        <div>
          <div className="-share-link-configuration">
            <div className="-header">
              <i className="fas fa-eye" /> Link settings
            </div>
            <div className="-body">
              <div className="-setting yt-row space-between">
                <div className="-instructions yt-col">
                  <p>
                    <strong>
                      Name<sup className="-required">*</sup>
                    </strong>
                  </p>
                </div>
                <div className="-inputs yt-col">
                  <TextInput
                    change={this._handleFormChange}
                    name="name"
                    placeholder={'Enter request list name'}
                    value={name}
                    onSubmit={this._handleCreate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

EngagementForm.propTypes = {}

EngagementForm.defaultProps = {
  selectedUsers: [],
  selectedClient: {},
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  return {
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(EngagementForm))
