/**
 * Boilerplate code for a new Redux-connected view component.
 * Nice for copy/pasting
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _cloneDeep from 'lodash/cloneDeep'
import _update from 'lodash/update'

// import actions
import * as clientActions from '../../../client/clientActions'
import * as fileActions from '../../../file/fileActions'

// import global components
import Binder from '../../../../global/components/Binder.js.jsx'
import Breadcrumbs from '../../../../global/components/navigation/Breadcrumbs.js.jsx'
import CloseWrapper from '../../../../global/components/helpers/CloseWrapper.js.jsx'
import TextInput from '../../../../global/components/forms/TextInput.js.jsx'
import DeletedRecords from '../../../../global/components/helpers/DeletedRecords.js.jsx'
import { FeedbackMessage } from '../../../../global/components/helpers/FeedbackMessage.js.jsx'

// import firm components
import PracticeLayout from '../../../../global/practice/components/PracticeLayout.js.jsx'
import UnderlineNav from '../../../../global/components/navigation/UnderlineNav.js.jsx'

// import utilities
import routeUtils from '../../../../global/utils/routeUtils'
import permissions from '../../../../global/utils/permissions'
import StarCheckbox from '../../../../global/components/forms/star-checkbox/StarCheckbox.js'

class WorkspaceLayout extends Binder {
  constructor(props) {
    super(props)
    this.feedbackMessage = React.createRef()
    this.state = {
      newOptionsOpen: false,
      newWorkflowOptionsOpen: false,
      updateClientNameOpen: false,
      clientName: '',
      breadcrumbs: {},
    }
    this._bind(
      '_toggleUpdateClientName',
      '_handleFormChange',
      '_handleUpdateClientName',
      '_handleSetBreadcrumbs',
      '_handleFavoriteToggle'
    )

    this.oldFolderId = props.match.params.folderId
  }

  componentDidMount() {
    const { dispatch, loggedInUser, match } = this.props
    if (match.params.folderId) {
      this._handleSetBreadcrumbs(match.params.folderId)
    }
    // dispatch(clientActions.fetchSingleIfNeeded(match.params.clientId));
    dispatch(clientActions.fetchListIfNeeded('_user', loggedInUser._id)) // this should live on every top-level route of the portal
    dispatch(
      clientActions.fetchListIfNeeded(
        '_firm',
        match.params.firmId,
        'status',
        'visible',
      ),
    )
  }

  componentWillReceiveProps(nextProps) {
    const nextMatch = nextProps.match
    const breadcrumbs = _cloneDeep(this.state.breadcrumbs)

    if (nextMatch.params.folderId && !breadcrumbs[nextMatch.params.folderId]) {
      this._handleSetBreadcrumbs(nextMatch.params.folderId)
    }
  }

  _handleSetBreadcrumbs(folderId) {
    const { match, dispatch, isAllFilesView } = this.props
    dispatch(
      fileActions.fetchParentFoldersIfNeeded(
        folderId,
        ...routeUtils.listArgsFromObject({ associatedParent: folderId })
      )
    ).then(json => {
      if (json.success && json.list.length) {
        this.oldFolderId = folderId

        const breadcrumbs = _cloneDeep(this.state.breadcrumbs)

        breadcrumbs[folderId] = []

        let path = `/firm/${match.params.firmId}/`
        if (match.params.userId) {
          path += `files/${match.params.userId}/personal/`
        } else if (!match.params.clientId) {
          path += `files/public/`
        } else if (match.params.clientId) {
          if (isAllFilesView) {
            path += `files/${match.params.clientId}/workspace/`
          } else {
            path += `workspaces/${match.params.clientId}/files/`
          }
        }

        json.list.map(file => {
          breadcrumbs[folderId].unshift({
            display: file.filename,
            path: path + file._id + '/folder',
          })
        })
        this.setState({ breadcrumbs })
      }
    })
  }

  componentWillUnmount() {
    this.setState({ breadcrumbs: {} })
    this.oldFolderId = null
  }
  _toggleUpdateClientName() {
    const { clientStore } = this.props
    this.setState({
      clientName: clientStore.selected.getItem().name,
      updateClientNameOpen: !this.state.updateClientNameOpen,
    })
  }

  _handleFormChange(e) {
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleUpdateClientName() {
    const { dispatch, clientStore } = this.props
    if (this.state.clientName.length > 0) {
      const newClient = _cloneDeep(clientStore.selected.getItem())
      newClient.name = this.state.clientName
      dispatch(clientActions.sendUpdateClient(newClient)).then(json => {
        if (json.success) {
          this.setState({ updateClientNameOpen: false, clientName: '' })
        }
      })
    } else {
      alert('Text field cannot be blank.')
    }
  }

  _handleFavoriteToggle(e) {
    const { dispatch, clientStore } = this.props
    const newClient = _cloneDeep(clientStore.selected.getItem())
    newClient.isFavorite = e.target.checked
    dispatch(clientActions.sendUpdateClient(newClient)).then(json => {
      if (!json.success) {
        console.error('Could not toggle workspace favorite!')
        this.feedbackMessage.current.showError(
          'Could not change workspace favorite status!'
        )
      } else {
        this.feedbackMessage.current.showSuccess(
          newClient.isFavorite
            ? 'Workspace marked as favorite!'
            : 'Workspace removed from favorites!'
        )
      }
    })
  }

  render() {
    const {
      clientStore,
      location,
      match,
      loggedInUser,
      selectedStaff,
      ownerPermissions,
      isAllFilesView,
    } = this.props
    const { updateClientNameOpen } = this.state

    const breadcrumbs = _cloneDeep(this.state.breadcrumbs)

    // client & firm
    const selectedClient = clientStore.selected.getItem()

    let headerTitle
    let clientOverview

    if (match.params.userId && selectedStaff) {
      if (loggedInUser._id == match.params.userId) {
        headerTitle = '(You) | Personal Files'
      } else {
        headerTitle = `${selectedStaff.firstname} ${selectedStaff.lastname} | Personal Files`
      }
    } else if (!match.params.clientId) {
      headerTitle = 'General Files'
    } else if (
      match.params.firmId &&
      match.params.clientId &&
      !isAllFilesView &&
      ownerPermissions
    ) {
      // clientOverview = [{
      //   display: "Go to Client Settings"
      //   , path: `/firm/${match.params.firmId}/clients/${match.params.clientId}`
      // }]
    }

    const links = [
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/overview`,
        display: 'Overview',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/files`,
        display: 'Files',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/request-list`,
        display: 'Request Lists',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/quick-tasks`,
        display: 'Quick Tasks',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/links`,
        display: 'Links',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/notifications`,
        display: 'Notifications',
      },
      // , { path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/invoices`, display: "Invoices" }
      // , { path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/payments`, display: "Payments" }
      // , { path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/details`, display: "Details" }
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/activity`,
        display: 'Activity',
      },
      {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/messages`,
        display: 'Messages',
      },
    ]

    if (ownerPermissions) {
      links.splice(6, 0, {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/contacts`,
        display: 'Contacts',
      })
      links.splice(7, 0, {
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/staff`,
        display: 'Assigned Staff',
      })
    } else {
      links.push({
        path: `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/users`,
        display: 'Users',
      })
    }

    let newBreadcrumbs = location.state.breadcrumbs
    if (
      match.params.folderId &&
      breadcrumbs[match.params.folderId] &&
      breadcrumbs[match.params.folderId].length
    ) {
      newBreadcrumbs = location.state.breadcrumbs.concat(
        breadcrumbs[match.params.folderId]
      )
    } else if (
      match.params.folderId &&
      this.oldFolderId &&
      breadcrumbs[this.oldFolderId]
    ) {
      newBreadcrumbs = location.state.breadcrumbs.concat(
        breadcrumbs[this.oldFolderId]
      )
    }


    const isFetchingClient = match.params.clientId
    && Object.keys(clientStore.byId).length === 0
    && !clientStore.byId[match.params.clientId]
    && (clientStore.isFetching || clientStore.selected.isFetching)

    const clientNotAvailable = match.params.clientId
    && !clientStore.byId[match.params.clientId]
    
    if (isFetchingClient) {
      return (
        <PracticeLayout isSidebarOpen={true}>
        <h2>Loading...</h2>
      </PracticeLayout>
      )
      
    }

    if (clientNotAvailable) {
      return (
        <PracticeLayout isSidebarOpen={true}>
          <DeletedRecords textErrorDisplay={"Looks like you don't have access to this workspace."} />
        </PracticeLayout>
      )
    }

    return (
      <PracticeLayout isSidebarOpen={false}>
        <FeedbackMessage ref={this.feedbackMessage} />
        <div className="-practice-subnav">
          <div className="yt-container fluid">
            <CloseWrapper
              isOpen={
                this.state.newOptionsOpen || this.state.newWorkflowOptionsOpen
              }
              closeAction={() =>
                this.setState({
                  newOptionsOpen: false,
                  newWorkflowOptionsOpen: false,
                })
              }
            />
            <div className="yt-row center-vert space-between">
              <Breadcrumbs
                links={newBreadcrumbs}
                otherLinks={clientOverview}
              />
            </div>
          </div>
        </div>
        <div className="yt-container fluid flex-row gap-3 items-center">
          {selectedClient !== null &&
          selectedClient !== undefined &&
          window.location.pathname.includes('workspace') ? (
            <StarCheckbox
              checked={selectedClient && selectedClient.isFavorite}
              onChange={this._handleFavoriteToggle}
            />
          ) : (
            <></>
          )}
          {updateClientNameOpen ? (
            <div className="client-name-wrapper">
              {/* <h1>{selectedClient ? selectedClient.name : <span className="loading"/>}</h1> */}
              <TextInput
                change={this._handleFormChange}
                name="clientName"
                value={this.state.clientName}
                placeholder="Enter new client name here..."
              />
              <button
                className="yt-btn x-small link"
                onClick={this._toggleUpdateClientName}
              >
                Cancel
              </button>
              <button
                className="yt-btn x-small success"
                onClick={this._handleUpdateClientName}
              >
                Save
              </button>
            </div>
          ) : (
            <h1
              onClick={headerTitle ? null : this._toggleUpdateClientName}
              className={`-tab-name -${
                match.params.clientId && selectedClient && selectedClient.status
              }`}
            >
              {headerTitle
                ? headerTitle
                : selectedClient && selectedClient.name}
            </h1>
          )}
        </div>
        <div className="-practice-content">
          <div className="yt-container fluid">
            {isAllFilesView ? null : (
              <div className="tab-bar-nav">
                <UnderlineNav
                  links={links}
                  classes="-client-workspace"
                />
              </div>
            )}
            <div className="-workspace-content">
              {match.params.clientId &&
              selectedClient &&
              selectedClient.status === 'deleted' ? (
                <DeletedRecords textErrorDisplay="The client has been deleted." />
              ) : (
                this.props.children
              )}
            </div>
          </div>
        </div>
      </PracticeLayout>
    )
  }
}

WorkspaceLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

WorkspaceLayout.defaultProps = {}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  const match = props.match
  const location = props.location
  const userStore = store.user
  const staffStore = store.staff
  const loggedInUser = store.user.loggedIn.user
  const selectedStaff =
    userStore.byId[match.params.userId] && match.params.userId
      ? userStore.byId[match.params.userId]
      : null
  const ownerPermissions = permissions.isStaffOwner(
    staffStore,
    loggedInUser,
    match.params.firmId
  )
  const isAllFilesView = location.pathname.split('/')[3]
    ? location.pathname.split('/')[3] === 'files'
    : false

  return {
    addressStore: store.address,
    clientStore: store.client,
    clientUserStore: store.clientUser,
    firmStore: store.firm,
    loggedInUser,
    staffStore,
    staffClientStore: store.staffClient,
    userStore,
    fileStore: store.file,
    selectedStaff,
    isAllFilesView,
    ownerPermissions,
  }
}

export default withRouter(connect(mapStoreToProps)(WorkspaceLayout))
