/**
 * View component for /files/new
 *
 * Creates a new file from a copy of the defaultItem in the file reducer
 */

// import primary libraries
import React from 'react'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import _update from 'lodash/update'
import _cloneDeep from 'lodash/cloneDeep'

// import actions
import * as requestActions from '../requestActions'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import Modal from '../../../global/components/modals/Modal.js.jsx'
import brandingName from '../../../global/enum/brandingName.js.jsx'
import FileInput from '../../../global/components/forms/FileInput.js.jsx'
import inviteUtils from '../../../global/utils/inviteUtils'

// import components

class RequestListBulkImport extends Binder {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      step: 'import-request-list',
      bulkRequestListObj: null,
    }
    this._bind(
      '_handleFormChange',
      '_handleFilesChange',
      '_handleClose',
      '_exportErrorReport',
      '_handleSubmit'
    )
  }

  componentDidMount() {}

  _handleFormChange(e) {
    /**
     * This let's us change arbitrarily nested objects with one pass
     */
    let newState = _update(_cloneDeep(this.state), e.target.name, () => {
      return e.target.value
    })
    this.setState(newState)
  }

  _handleClose() {
    this.setState(
      {
        submitting: false,
        step: 'import-request-list',
        bulkRequestListObj: null,
      },
      () => {
        this.props.close()
      }
    )
  }

  _handleFilesChange(files) {
    console.log('eyyy', this.props)

    const match = this.props.match
    this.setState({ step: 'preparing' })

    let submitObj = {
      firmId: match.params.firmId,
      clientId: match.params.clientId,
    }

    let file = files[0]
    let reader = new FileReader()

    reader.onload = () => {
      const bulkRequestListObj = inviteUtils.getCSVSubmitRequestListObj(
        reader.result,
        submitObj
      )
      console.log(' reader result', reader.result, bulkRequestListObj)

      if (bulkRequestListObj.success) {
        this.setState({
          bulkRequestListObj,
          step: 'import-review',
        })
      } else {
        alert(bulkRequestListObj.message)
        this.setState({ step: 'import-request-list' })
      }
    }

    reader.readAsBinaryString(file)
  }

  _exportErrorReport() {
    const bulkRequestListObj = _cloneDeep(this.state.bulkRequestListObj)
    let csv = inviteUtils.generateRequestListErrorReport(
      bulkRequestListObj.errorArray
    )
    const filename = `REQUEST_LIST_IMPORT_ERRORS_${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}.csv`
    // download as a file when the user clicks the button
    let csvFile = new Blob([csv], { type: 'text/csv' })
    let data = URL.createObjectURL(csvFile)
    let link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    link.click()
    // Now that they've downloaded the errors, let them continue with submitting the valid clients.
    bulkRequestListObj.errorArray = []
    this.setState({ bulkRequestListObj })
  }

  _handleSubmit() {
    const { dispatch, listArgs } = this.props
    const bulkRequestListObj = _cloneDeep(this.state.bulkRequestListObj)

    this.setState({ submitting: true })
    dispatch(requestActions.sendBulkCreateRequest(bulkRequestListObj)).then(
      json => {
        if (json.success) {
          json.list.map(item => {
            dispatch(requestActions.addSingleRequestToMap(item))
            dispatch(requestActions.addRequestToList(item._id, ...listArgs))
          })
          this._handleClose()
        } else {
          alert(json.message)
        }
      }
    )
  }

  render() {
    const { isOpen } = this.props

    const { submitting, step, bulkRequestListObj } = this.state

    let modalHeaderText = ''
    let headerText = ''
    let showButton = true
    if (step === 'import-request-list') {
      modalHeaderText = 'Step 1 of 3'
      headerText = 'Upload your CSV file'
      showButton = false
    } else if (step === 'import-review') {
      modalHeaderText = 'Step 2 of 3'
      headerText = 'Review your CSV file'
    } else {
      modalHeaderText = 'Step 3 of 3'
      headerText = 'Import Result'
    }

    return (
      <Modal
        cardSize="large"
        isOpen={isOpen}
        closeAction={this._handleClose}
        closeText="Cancel"
        confirmAction={this._handleSubmit}
        confirmText={submitting ? 'Saving' : 'Continue importing'}
        disableConfirm={
          submitting ||
          !!(bulkRequestListObj && bulkRequestListObj.errorArray.length)
        }
        modalHeader={modalHeaderText}
        showButtons={showButton}
      >
        <div>
          <div className="-share-link-configuration">
            <div className="-header">{headerText}</div>
            <div className="-body">
              {step === 'import-request-list' ? (
                <div className="yt-col full">
                  <br />
                  <div className="yt-row">
                    <p>
                      Import data into {brandingName.title} from CSV files.{' '}
                      <a
                        href="/request_list_bulk_import_template_csv.csv"
                        target="_blank"
                        download
                      >
                        Click here to download a CSV template.
                      </a>
                    </p>
                  </div>
                  <div className="yt-row">
                    <div className="yt-col full">
                      {submitting ? (
                        <div className="-loading-hero hero">
                          <div className="u-centerText">
                            <div className="loading"></div>
                          </div>
                        </div>
                      ) : (
                        <FileInput
                          accepts={['.csv']}
                          multiple={false}
                          change={this._handleFilesChange}
                          required={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : step === 'import-review' ? (
                <div className="yt-col full">
                  <br />
                  <div className="yt-row">
                    <p>
                      <strong>
                        Request lists to import:{' '}
                        {bulkRequestListObj.requestLists.length}
                      </strong>
                    </p>
                    {bulkRequestListObj.errorArray.length ? (
                      <div>
                        <p>
                          <strong>
                            Errors found: {bulkRequestListObj.errorArray.length}
                          </strong>
                          <button
                            onClick={this._exportErrorReport}
                            className="yt-btn warn u-pullRight small"
                          >
                            Download error report
                          </button>
                        </p>
                        <p className="u-danger">
                          <strong>
                            We found errors with{' '}
                            {bulkRequestListObj.errorArray.length} of the{' '}
                            {bulkRequestListObj.errorArray.length +
                              bulkRequestListObj.requestLists.length}{' '}
                            request lists in your csv file!
                          </strong>
                        </p>
                        {bulkRequestListObj.requestLists.length ? (
                          <div>
                            <small>
                              Don't worry, you can still import the other{' '}
                              {bulkRequestListObj.requestLists.length} request
                              lists, but first click above to download an error
                              report in the same format as your upload.
                            </small>
                            <small>
                              You can fix the errors and import those request
                              lists later.
                            </small>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="yt-row">
                    <table className="yt-table -multiple-data">
                      <thead>
                        <tr>
                          <th></th>
                          <th>List Name</th>
                          <th>Category</th>
                          <th>Description</th>
                          <th>Due Date</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bulkRequestListObj.errorArray.length === 0
                          ? null
                          : bulkRequestListObj.errorArray.map((list, i) => (
                              <tr key={'errorList_' + i}>
                                <td>
                                  <i className="u-danger fas fa-times-octagon" />
                                </td>
                                <td>{list.name}</td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.category}-${j}`}>
                                      {item.category}
                                    </p>
                                  ))}
                                </td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.description}-${j}`}>
                                      {item.description}
                                    </p>
                                  ))}
                                </td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.dueDate}-${j}`}>
                                      {item.dueDate}
                                    </p>
                                  ))}
                                </td>
                                <td>{list.error}</td>
                              </tr>
                            ))}
                        {bulkRequestListObj.requestLists.length === 0
                          ? null
                          : bulkRequestListObj.requestLists.map((list, i) => (
                              <tr key={'errorList_' + i}>
                                <td>
                                  <i className="u-success fas fa-check" />
                                </td>
                                <td>{list.name}</td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.category}-${j}`}>
                                      {item.category}
                                    </p>
                                  ))}
                                </td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.description}-${j}`}>
                                      {item.description}
                                    </p>
                                  ))}
                                </td>
                                <td>
                                  {list.tasks.map((item, j) => (
                                    <p key={`${item.dueDate}-${j}`}>
                                      {item.dueDate}
                                    </p>
                                  ))}
                                </td>
                                <td>{list.error}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

RequestListBulkImport.propTypes = {}

RequestListBulkImport.defaultProps = {
  selectedClient: {},
}

const mapStoreToProps = (store, props) => {
  /**
   * NOTE: Yote refer's to the global Redux 'state' as 'store' to keep it mentally
   * differentiated from the React component's internal state
   */

  return {
    loggedInUser: store.user.loggedIn.user,
  }
}

export default withRouter(connect(mapStoreToProps)(RequestListBulkImport))
