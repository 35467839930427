// import primary libraries
import React from 'react'
import { Switch } from 'react-router-dom'

// import global components
import Binder from '../../global/components/Binder.js.jsx'
import YTRoute from '../../global/components/routing/YTRoute.js.jsx'

// import quickTask views
import StyleGuideLayout from './StyleGuideLayout.js.jsx'

class StyleGuideUrlRouter extends Binder {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Switch>
        <YTRoute
          exact
          path="/style-guide"
          component={StyleGuideLayout}
        />
      </Switch>
    )
  }
}

export default StyleGuideUrlRouter
