import React, { ReactDOMServer, useEffect } from 'react'
import { renderToStaticMarkup, re } from 'react-dom/server'
import PracticeUISwitcher from '../../../global/practice/components/PracticeUISwitcher.js.jsx'

const GridExample = () => {
  const html = `<h1>This is a test of passing the text as a variable</h1>
                    <p>Test</p>`
  const markup = renderToStaticMarkup(<PracticeUISwitcher />)
  return renderToStaticMarkup(html)
}

//export default GridExample;

class GridSample2 extends React.Component {
  constructor(props) {
    super(props)
    this.html = `<h1>This is a test of passing the text as a variable</h1>
                    <p>Test</p>`
  }

  testFunc() {
    return 'I got it'
  }

  render() {
    return renderToStaticMarkup(this.html)
  }
}

export default GridSample2
