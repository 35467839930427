// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link'
import withRouter from 'react-router-dom/withRouter'

import { DateTime } from 'luxon'

import CheckboxInput from '../../../../global/components/forms/CheckboxInput.js.jsx'
import displayUtils from '../../../../global/utils/displayUtils'

const PracticeContactListItem = ({
  address,
  match,
  phoneNumber,
  user,
  handleSelectUser,
  checked,
}) => {
  return (
    <tr>
      <td data-label="Select">
        <CheckboxInput
          name="user"
          value={checked}
          change={() => handleSelectUser(user._id)}
          checked={checked}
        />
      </td>
      <td data-label="Contact">
        <Link
          to={`/firm/${match.params.firmId}/contacts/quick-view/${user._id}`}
        >
          {user.contactFullName}
        </Link>
      </td>
      <td data-label="Email">{user.contactEmail}</td>
      <td data-label="Phone">{phoneNumber || <em>n/a</em>}</td>
      <td data-label="Address">{address || <em>n/a</em>}</td>
    </tr>
  )
}

PracticeContactListItem.propTypes = {
  address: PropTypes.object,
  phoneNumber: PropTypes.object,
  user: PropTypes.object.isRequired,
}

PracticeContactListItem.defaultProps = {
  address: null,
  phoneNumber: null,
}

export default withRouter(PracticeContactListItem)
